<template>
  <div class="limitation-width fixed-box">
    <link :href="skincss" rel="stylesheet" id="skincss"/>
    <van-nav-bar fixed :title="$route.query.uName" @click-left="goback">
      <template #left>
        <van-icon name="arrow-left" color="#fff" size="18" />
        <!-- <span style="color: #fff">返回</span> -->
      </template>
    </van-nav-bar>

    <div class="cur-head">
      <van-tabs :ellipsis="false" v-model="activeName" @click="imgTab">
        <van-tab
          v-for="(item, i) in photoData"
          :key="i"
          :name="i"
          :title="item.FName"
        ></van-tab>
      </van-tabs>
      <div class="cur-more">
        <van-dropdown-menu>
          <van-dropdown-item ref="dropdownItemRef">
            <template #title>
              <img src="/images/app-561b2105.png" alt="" />
            </template>
            <div class="more-list">
              <div
                class="more-item"
                v-for="(item, i) in photoData"
                :key="i"
                @click="imgTab(i)"
              >
                {{ item.FName }}
              </div>
            </div>
          </van-dropdown-item>
        </van-dropdown-menu>
      </div>
    </div>

    <div class="cur-main">
      <!-- <Draw :hideXg="true" /> -->
      <div v-if="curPhotoData.length" class="cur-img">
        <img :src="curPhotoData[0].FImg" alt="" />
        <div class="cur-keep" @click="setKeep(curPhotoData[0])">
          <van-icon
            name="star"
            v-if="keepList.indexOf(curPhotoData[0].FID) != -1"
          />
          <van-icon name="star-o" v-else />
        </div>
        <div class="cur-btns">
          <div class="cur-btn" @click="nextImg(1)">
            <van-icon name="arrow-left" />
            <span>上一期</span>
          </div>
          <div class="cur-btn" @click="nextImg(2)">
            <span>下一期</span>
            <van-icon name="arrow" />
          </div>
        </div>
      </div>

      <div v-if="otherPhotoData.length" class="other-img">
        <h2>往期图纸推荐</h2>
        <ul>
          <li
            v-for="(item, i) in otherPhotoData"
            :key="i"
            @click="jumpDetail(item)"
          >
            <img :src="item.FImg" alt="" />
            <div class="other-desc">
              <h4>{{ item.FSubType1 }}</h4>
              <p>
                <span>{{ item.FName }}</span>
                <span>{{ item.FRead }}人看过</span>
              </p>
            </div>
            <div class="cur-keep" @click.stop="setKeep(item)">
              <van-icon name="star" v-if="keepList.indexOf(item.FID) != -1" />
              <van-icon name="star-o" v-else />
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from '@/components/Banner'
import Draw from '@/components/Draw'
import Tiezi from '@/components/Tiezi'
import {
  getForumInfo,
  UpdateForumLikes,
  AddForumComment,
  getMyLikes,
  getMyFavorites,
  UpdateForumFavorites,
  getDataPhoto,
  getDataPhoto2
} from '@/api/forum'
import { mapGetters } from 'vuex'
import LoginBox from '@/views/my/LoginBox.vue'
import { Toast } from 'vant'
export default {
  name: 'Detail',
  components: { Banner, Draw, Tiezi, LoginBox },
  data() {
    return {
      detailInfo: {},
      popupShow: false,
      loginShow: false,
      commentShow: false,
      message: '',
      isLike: false,
      isGive: false,
      isKeep: false,
      copyContent: '',
      photoData: [],
      uFID: '',
      uName: '',
      activeName: 0,
      value: 0,
      switch1: false,
      switch2: false,
      option: [
        { text: '全部商品', value: 0 },
        { text: '新款商品', value: 1 },
        { text: '活动商品', value: 2 },
      ],
      keepList: '',
    }
  },
  watch: {
    '$route.query.uFID'(val) {
      this.uFID = val
      this.setActiveName()
      document.querySelector('.cur-main').scrollTo(0, 0)
      this.getForumInfo()
    },
  },
  computed: {
    ...mapGetters({ userInfo: 'user/userInfo' }),
    curPhotoData() {
      let filterData = this.photoData.filter((item) => item.FID == this.uFID)
      return filterData
    },
    otherPhotoData() {
      let filterData = this.photoData.filter((item) => item.FID != this.uFID)
      return filterData
    },

    skincss() {
      if (process.env.VUE_APP_BASE_Skin && process.env.VUE_APP_BASE_Skin != 'default') {
        return '/mobile/skin/' + process.env.VUE_APP_BASE_Skin + '.css?'+new Date().getTime()
      }
      return '/mobile/skin/default.css'
    },
  },
  async created() {
    let uFID = this.$route.query.uFID
    let uName = this.$route.query.uName
    this.uFID = uFID
    this.uName = uName
    await this.getDataPhoto()
    await this.getForumInfo()
    // 我的点赞
    // this.getLikeData()
    //我的收藏
    this.getKeepData()
    this.copyContent = location.href
  },

  methods: {
    jumpDetail(data) {
      let path =
        '/gallery/detail/' +
        this.$route.params.id +
        '?uFID=' +
        data.FID +
        '&uName=' +
        data.FSubType1
      this.$router.replace(path)
    },
    imgTab(activeName) {
      this.activeName = activeName
      this.setFid()
      this.$refs.dropdownItemRef.toggle(false)
    },
    nextImg(type) {
      if (type == 1) {
        if (this.activeName <= 0) return Toast('没有了')
        this.activeName--
        this.setFid()
      } else {
        if (
          this.photoData.length &&
          this.activeName >= this.photoData.length - 1
        )
          return Toast('没有了')
        this.activeName++
        this.setFid()
      }
    },
    setActiveName() {
      let data = this.photoData
      for (let i = 0; i < data.length; i++) {
        if (data[i].FID == this.uFID) {
          this.activeName = i
        }
      }
    },
    setFid() {
      this.uFID = this.photoData[this.activeName].FID
    },
    async getDataPhoto() {
      let uGameNo = this.$route.params.id
      let uName = this.$route.query.uName
      let uType = 37
      if (uGameNo == '90001') {
        uType = 5
      } else if (uGameNo == '90002') {
        uType = 21
      } else if (uGameNo == '90004') {
        uType = 13
      } else {
        uType = 37
      }
      let uSubType = uName || ''
      let { list } = await getDataPhoto2({ uGameNo, uType, uSubType })
      if (list.length < 1) return
      let filterData = list.filter((item) => item.FSubType1.includes(uName))

      this.photoData = filterData
      // console.log(filterData)
      this.setActiveName()
      // if (filterData.length < 1) {
      //   this.photoData = null
      //   this.allPhotoData = null
      // } else {
      //   this.sortData(filterData)
      // }
    },
    handleCopy() {
      Toast('复制链接成功')
    },
    onCopy() {
      let oInput = document.createElement('input')
      oInput.value = this.copyContent
      document.body.appendChild(oInput)
      oInput.select() // 选择对象;
      //console.log(oInput.value)
      document.execCommand('Copy') // 执行浏览器复制命令
      Toast('复制链接成功')
      oInput.remove()
    },
    jumpRecord() {
      let curlottery = localStorage.getItem('curlottery')
      let path = '/record/' + curlottery
      this.$router.push(path)
    },
    closeLoginBox() {
      // 我的点赞
      this.getLikeData()
      //我的关注
      this.getKeepData()
      this.loginShow = false
    },
    jumpMy() {
      if (this.isLogin()) {
        this.$router.push('/user/' + this.detailInfo.FUserID)
      }
    },
    jumpHistory() {
      let paht =
        '/detail/history/' +
        this.$route.params.id +
        '?uFUserID=' +
        this.detailInfo.FUserID
      this.$router.push(paht)
    },
    // 显示发布帖子
    showSentComment() {
      if (this.isLogin()) {
        this.commentShow = true
      }
    },
    // 获取帖子信息
    async getForumInfo() {
      let uFID = this.$route.query.uFID
      let uID = this?.userInfo?.id || 0
      let { list: detailInfo } = await getForumInfo({ uFID, uID })
      this.detailInfo = detailInfo[0]
    },
    // 点赞
    setLikes() {
      if (this.isLogin()) {
        let token = localStorage.getItem('token')
        let uID = this.userInfo.id
        let uFID = this.detailInfo.FID
        UpdateForumLikes({ token, uID, uFID }).then((res) => {
          // //console.log(res)
          if (res.success) {
            this.getForumInfo()
            this.isLike = true
          }
        })
      }
    },
    // 获取点赞数据
    async getLikeData() {
      let token = localStorage.getItem('token')
      let uID = this?.userInfo?.id || 0
      let uFID = this.detailInfo.FID
      let { list: likeData } = await getMyLikes({ token, uID })

      let isLike = likeData.find(
        (item) => item.FUserID == uID && item.FID == uFID
      )
      //console.log('isLike', isLike)
      if (isLike) this.isLike = true
    },

    setKeep(data) {
      // 收藏
      // if (this.isLogin()) {
      let token = localStorage.getItem('token')
      if (!token) {
        return Toast('请先登录！')
      }
      let uID = this?.userInfo?.id
      let uFID = data?.FID
      let uFState = 1
      if (this.keepList.indexOf(uFID) != -1) {
        uFState = 0
      }
      UpdateForumFavorites({ token, uID, uFID, uFState }).then((res) => {
        if (res.success) {
          this.getKeepData()
          // this.isKeep = !this.isKeep
        }
      })
      // }
    },
    // 获取收藏数据
    async getKeepData() {
      let token = localStorage.getItem('token')
      let uID = this?.userInfo?.id || 0
      let uFID = this?.detailInfo?.FID
      // if (!uFID) return
      let { list: keepData } = await getMyFavorites({ token, uID })

      let res = keepData.map((item) => {
        return item.FID
      })

      // let isKeep = keepData.find(
      //   (item) => item.FUserID == uID && item.FID == uFID
      // )
      //console.log('isKeep', isKeep)
      // if (isKeep) this.isKeep = true
      this.keepList = res
    },
    isLogin() {
      if (this.userInfo && this.userInfo.id) {
        return true
      } else {
        this.loginShow = true
        return false
      }
    },
    // 发布评论
    addBBS() {
      let token = localStorage.getItem('token')
      let uID = this.userInfo.id
      let uFID = this.$route.query.uFID
      let uContent = this.message
      if (!uContent) return this.$global.baseToast('请输入内容')
      AddForumComment({ token, uID, uFID, uContent }).then((res) => {
        if (res.success) {
          this.commentShow = false
          this.$refs.tieziRef.getForumComment()
        }
      })
    },
    commentIsLogin() {
      this.isLogin()
    },
  },
}
</script>

<style lang="scss" scoped>
.limitation-width {
  // ::v-deep .van-nav-bar {
  //   background-color: #fff;
  //   border-bottom: 1px solid #eee;
  //   color: #000;
  //   .van-nav-bar__title {
  //     color: #000;
  //     // font-weight:bold ;
  //   }
  // }
  .cur-keep {
    width: 30px;
    height: 30px;
    color: #fff;
    border-radius: 50%;
    position: absolute;
    top: 20px;
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba($color: #000000, $alpha: 0.4);
    i {
      font-size: 18px;
    }
  }
  .cur-img {
    position: relative;
    padding: 10px 0;
    img {
      width: 100%;
    }
    .cur-btns {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;
      margin-top: 10px;
      .cur-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 48px;
        width: calc(50% - 40px);
        border-radius: 5px;
        background-color: #e4e6ed;
        position: relative;
        gap: 20px;
        span {
          font-size: 16px;
          color: rgb(121 123 136);
        }
      }
    }
  }
  .cur-head {
    margin-top: 10px;
    position: absolute;
    width: 100%;
    z-index: 9;
    ::v-deep .van-tabs {
      .van-tabs__nav {
        background-color: transparent;
      }
      .van-tabs__wrap {
        height: auto;
      }
      .van-tab {
        height: 28px;
        min-width: 28px;
        padding: 0 20px;
        margin: 0;
        margin-right: 10px;
        line-height: 1;
        border-radius: 28px;
        border: 1px solid #eee;
        &.van-tab--active {
          .van-tab__text {
            color: #fff;
          }
        }
      }
      .van-tab__text {
        color: rgb(141 145 157);
        font-size: 14px;
      }
      .van-tabs__nav--line {
        padding-bottom: 0;
      }
      .van-tabs__line {
        display: none;
      }
    }
    .cur-more {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 0;
      top: 0;
      background-color: #fff;
      padding: 0 5px;
      overflow: hidden;
      img {
        width: 20px;
      }
      ::v-deep .van-dropdown-menu__title--down::after {
        display: none;
      }
      .more-list {
        display: flex;
        flex-wrap: wrap;
        padding: 10px 8px;
        gap: 10px;
        max-height: 350px;
        min-height: 100px;
        .more-item {
          // background: red;
          height: 25px;
          width: calc(33.33% - 8px);
          line-height: 25px;
          text-align: center;
          color: rgb(154 154 154);
          border: 1px solid rgb(154 154 154);
          border-radius: 10px;
        }
      }
    }
  }
  .cur-main {
    width: 100%;
    position: absolute;
    top: 90px;
    height: calc(100% - 90px);
    overflow: auto;
  }
  .other-img {
    padding: 8px;
    h2 {
      font-size: 16px;
      padding: 10px 10px;
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      li {
        width: calc(50% - 5px);
        background-color: #f2f3f5;
        border-radius: 5px;
        position: relative;
        img {
          width: 100%;
        }
        .other-desc {
          padding: 8px;
          h4 {
            font-size: 14px;
            margin-bottom: 8px;
          }
          p {
            display: flex;
            align-items: center;
            justify-content: space-between;
            span {
              font-size: 10px;
            }
          }
        }
      }
    }
  }
}
</style>
