var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "home-draw" }, [
    _c("div", { staticClass: "number_draw" }, [
      _c("div", { staticClass: "nd_top" }, [
        _c("div", [
          _vm._v(" 第 "),
          !_vm.isHideOpenCode
            ? _c("span", [_vm._v(" " + _vm._s(_vm.lotteryData?.period) + " ")])
            : _vm._e(),
          _vm._v(" 期 开奖结果 "),
          _c("img", {
            staticStyle: { "vertical-align": "text-top" },
            attrs: {
              width: "25px",
              src: require("@/assets/images/sx.png"),
              alt: "",
            },
            on: { click: _vm.getLotteryData2 },
          }),
        ]),
        _c(
          "a",
          { attrs: { href: "javascript:;" }, on: { click: _vm.jumpRecord } },
          [_vm._v("历史开奖结果>")]
        ),
      ]),
      !_vm.isHideOpenCode
        ? _c(
            "div",
            { staticClass: "nd_plural aaaaaaaa" },
            [
              _vm.lotteryData?.openCode
                ? _vm._l(_vm.lotteryData.openCode, function (item, i) {
                    return _c(
                      "div",
                      { key: i, staticClass: "boll-box" },
                      [
                        _vm.enableRoll
                          ? [
                              !_vm.lotteryData.bose[i].trim() && i != 6
                                ? [_c("MyRoll")]
                                : [
                                    _c(
                                      "strong",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: i != 6,
                                            expression: "i != 6",
                                          },
                                        ],
                                        class: _vm.lotteryData.bose[i],
                                      },
                                      [_vm._v(" " + _vm._s(item) + " ")]
                                    ),
                                  ],
                            ]
                          : [
                              _c(
                                "strong",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: i != 6,
                                      expression: "i != 6",
                                    },
                                  ],
                                  class: _vm.lotteryData.bose[i],
                                },
                                [_vm._v(" " + _vm._s(item) + " ")]
                              ),
                            ],
                        _c(
                          "p",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: i != 6,
                                expression: "i != 6",
                              },
                            ],
                          },
                          [
                            _c("span", [
                              _vm._v(_vm._s(_vm.lotteryData.sxiao[i])),
                            ]),
                            _vm.lotteryData.wxing[i]
                              ? _c("b", [_vm._v("/")])
                              : _vm._e(),
                            !_vm.lotteryData.wxing[i]
                              ? _c("b", { staticStyle: { opacity: "0" } }, [
                                  _vm._v("/"),
                                ])
                              : _vm._e(),
                            _c("span", [
                              _vm._v(_vm._s(_vm.lotteryData.wxing[i])),
                            ]),
                          ]
                        ),
                        _c(
                          "h3",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: i == 6 && item == "+",
                                expression: "i == 6 && item == '+'",
                              },
                            ],
                            staticClass: "asaassaasas",
                          },
                          [_vm._v(_vm._s(item))]
                        ),
                      ],
                      2
                    )
                  })
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
      _c("div", { staticClass: "nd_info", staticStyle: { margin: "15px 0" } }, [
        _vm.isCountDown
          ? _c("p", [
              _c(
                "span",
                { staticStyle: { "flex-direction": "column", gap: "5px" } },
                [
                  _c("div", [
                    _vm._v(" 第 "),
                    _vm.lotteryData?.period
                      ? _c("b", [_vm._v(" " + _vm._s(_vm.nextQh) + " ")])
                      : _vm._e(),
                    _vm._v(" 期 倒计时 "),
                  ]),
                  _c(
                    "div",
                    { staticStyle: { "font-size": "12px", color: "#999" } },
                    [
                      _vm._v(
                        " 开奖日期 " +
                          _vm._s(
                            _vm.$formatTime(_vm.nextOpenTime, "YYYY/MM/DD")
                          ) +
                          " "
                      ),
                    ]
                  ),
                ]
              ),
              _c(
                "span",
                [
                  _c("van-count-down", {
                    attrs: { time: _vm.timeDown, format: "HH:mm:ss" },
                    on: { change: _vm.onChange, finish: _vm.onFinish },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (timeData) {
                            return [
                              _c("span", { staticClass: "block" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      timeData.hours > 9
                                        ? timeData.hours
                                        : "0" + timeData.hours
                                    ) +
                                    " "
                                ),
                              ]),
                              _c("span", { staticClass: "colon" }, [
                                _vm._v(":"),
                              ]),
                              _c("span", { staticClass: "block" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      timeData.minutes > 9
                                        ? timeData.minutes
                                        : "0" + timeData.minutes
                                    ) +
                                    " "
                                ),
                              ]),
                              _c("span", { staticClass: "colon" }, [
                                _vm._v(":"),
                              ]),
                              _c("span", { staticClass: "block" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      timeData.seconds > 9
                                        ? timeData.seconds
                                        : "0" + timeData.seconds
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1046938521
                    ),
                  }),
                ],
                1
              ),
            ])
          : _c("span", [
              _vm._v(
                " 第 " +
                  _vm._s(
                    _vm.lotteryData?.period
                      ? _vm.lotteryData?.period.slice(-3) * 1 + 1
                      : 0
                  ) +
                  "期 " +
                  _vm._s(_vm.nextOpenTime) +
                  " " +
                  _vm._s(_vm.getDay(_vm.nextOpenTime)) +
                  " "
              ),
            ]),
      ]),
      _vm.ShowBuy || _vm.ShowVideo
        ? _c("div", { staticClass: "nd-button2" }, [
            _vm.ShowVideo
              ? _c(
                  "div",
                  { staticClass: "button", on: { click: _vm.jumeKj } },
                  [_vm._m(0), _c("span", [_vm._v("现场直播开奖")])]
                )
              : _vm._e(),
            _vm.ShowBuy
              ? _c(
                  "a",
                  {
                    staticClass: "button",
                    staticStyle: {
                      "margin-left": "auto",
                      "margin-right": "0.1rem",
                    },
                    attrs: { href: _vm.buyurl },
                  },
                  [_c("span", [_vm._v("立即购彩")])]
                )
              : _vm._e(),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("img", {
        attrs: {
          src: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEEAAABBCAYAAACO98lFAAAACXBIWXMAAAsTAAALEwEAmpwYAAAFcElEQVR4nN3ce6xcVRXH8c+cYnlJDQlJY9I0JUjKozQtUQvaiFoUQwm0iUAsz2BTJfEPYk2oijRIi1GxSf8xJUJ4tsWIBXlISChVmlJaanyLJUUssVAIqQKtCJVz/WPNcIfpnbkzc/Y5c6/f5GTmvPZa87vnsfdaa9/a3ms3GFIzRMtnTV7/jO+xPR9qrGfv7c8PWbLm9Vm57IxcbXY+VJuay6bnasfmakfmaoc3HftWLvtPrvZKrrYzl+3N1bbkQ7WtuWxXh/Zb/Mia/NTyG2qH/MYhHCY9E3A2LsBnMb3L846sL8fipPq2ryDHDjyOB7EtpbOkFWEKvoYv4oSE7Wb4eH35Fn6LO7AG76QyUJTJuBnP4VppBRiJ2ViNnfgGakUbLCrC1XgWS8WlXCXT8EP8AecWaahfET6CJ/BjcQ8Pkhl4BLfr8w/RjwiL8Ht8ph+DJXKl8OvMXk/sVYSVWIujejVUESfiKXy5l5N6EeFW8XQeD9yKFd0e3O0rcgMW9uXO4Pg2jhBvkI50cyXcY/wJ0GApvj7aQaOJ8D1cksSdwfEjLOh0QCcRFmBZQmcGyf2Y2m5nOxE+jHtLcWdwPNxuRzsRfobDy/FlYJwmbu9DGEmEy/DJUt0ZHMtEb/d9tIrwIdEVTsHb+DkeStReKta3bmgVYSk+mMDQq5gphtXn4wz8MkG7KfiolgFXswgTRDwgBcvF0LrBNswX446/JrJRhJXNK80iXCXdiPDXbbavxym4Hm8lstUPs/DpxkqzCKmuAuKqascQbsSpuCuhzV5Z0vjSEGGGuIdT0c2Y5AVcgXnYktB2t8zH0QyLcPkAnGjwBOaKKNVLFdqdhHMYFqFQeCoRa3AyVlVo8zxChOPF/TkWeEO8pmeK8HrZzCNEOLsCY73yR5G3WIg/lWhnKmZm4qE4VnlA9PmXiaukDGZkOgwxxxDfF8+L20po+/hM+cmSVLyExfgEnkzY7okZjkvYYBVsxVkixP5ygvYmZ8Zv3OBO8Twr2tE6KlPvNY1T9okY6MECbUzK8IE0/gyM3SJ20S8TM4MdzaXgAsViIPvHuwhfEInYIoxbEU7AOjyqeAzkQIZ/FHapOo7GDaIm4kuJ2tydYVeixsrmcvxFRKVSPsx3Hoa/J2ywDOaKfMHcktp/IRM9sLHIVFGgtVl5AhzEM1ndyP6SjPTDRHxHRKuvKNnWDryaCQGS1wb2ySJRlfZd1XTnNzEcXnugAoOdOBO/EqVA0yq0+wuGRViH/1ZovMEU/ETUGZ1Vse3nsZ1hEfbpkLrug9H68jV8U2SjFie02ws/bXxpTr7cnNDAER32XSQ6OzcZ7Aj2lsaXZhG2iDrAFHx1hG1z8Jj4C3Rb9F0Wa/FiY6U1U7RCFGgUZQn24m4cI0RZ0vGMalnevNIqwn3idTkngaHr68tYY5V4KL7HSJUqi6rxZSDsEcmd9zGSCH8TJfv/j1w80sZ2hVs/wMbyfBkIK7UJyo5Wx1hW1qdqnsJ17XZ2EmG/SFgOpfaoYp4XYbi2jFbWuwOfS+ZO9fxblOW82emgbgq8N4qI7njjHXxKF+HDbuc7PCim971bwKkq2YPT8ZtuDu5l0scmEeF5pQ+nqmQ7PoY/d3tCr9N/nhb5v7FWpdpgtejt9pSo7Wci2GuiSvUaHOjj/DLYJV7p1/RzcpF5katFYeYgpwTkooBjhnqUqB+KTg59USRB5ohUeVW8JuIR00UpT5GEbLK50tvry024FBcanvSdks0iHrEW/0rVaOpZ888ZHkLPw+dF7HCm/mav/hPPiILPx/C7JF62UMa/Dmiw0fAgbIoovJomniPHiYrSSSIUdwCvi676yyLdtkfMkN9Xoo/gf9jvJGFHPMiUAAAAAElFTkSuQmCC",
          alt: "",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }