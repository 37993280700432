<template>
  <div class="limitation-width">
    <link :href="skincss" rel="stylesheet" id="skincss"/>
    <div class="gallery-head">
      <van-tabs :ellipsis="false" @click="czTab">
        <van-tab
          v-for="(item, i) in lotteryData"
          :key="i"
          :title="item.gameName"
        ></van-tab>
      </van-tabs>
    </div>
    <div class="gallery-main">
      <div class="gallery-search">
        <div class="search-box">
          <input
            type="text"
            v-model="searchVal"
            placeholder="請輸入您要查找的图纸名称"
          />
          <div class="gicon" @click="getDataPhoto">
            <van-icon name="search" />
          </div>
        </div>
      </div>
      <div class="gallery-order">
        <van-tabs :ellipsis="false" v-model="activeName" @click="orderTab">
          <van-tab
            v-for="(item, i) in allEn"
            :name="i"
            :key="i"
            :title="item"
          ></van-tab>
        </van-tabs>
      </div>
      <div class="gallery-cont">
        <div v-if="photoData" class="list">
          <template v-if="activeName != 0">
            <div
              class=""
              v-for="(item, index) in Object.entries(photoData)"
              :key="index"
            >
              <h2>{{ item[0] }}</h2>
              <div class="gallery-list">
                <div
                  class="gallery-item"
                  v-for="valItem in item[1]"
                  :key="valItem.FID"
                  @click="jumpDetail(valItem)"
                >
                  <img :src="valItem.FImg" alt="" />
                  <div class="gallery-info">
                    <h4>{{ valItem.FSubType1 }}</h4>
                    <span>{{ valItem.FName }}</span>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="gallery-list">
              <div
                class="gallery-item"
                v-for="valItem in photoList"
                :key="valItem.FID"
                @click="jumpDetail(valItem)"
              >
                <img :src="valItem.FImg" alt="" />
                <div class="gallery-info">
                  <h4>{{ valItem.FSubType1 }}</h4>
                  <span>{{ valItem.FName }}</span>
                </div>
              </div>
            </div>
          </template>
        </div>
        <van-empty description="暂无数据" v-else />
      </div>
    </div>
  </div>
</template>

<script>
import { getLottery } from '@/api/lottery'
import pinyin from 'pinyin'
import { getDataPhoto } from '@/api/forum'
import { capitalize, orderBy, merge } from 'lodash'
import { Toast } from 'vant'
export default {
  name: 'Gallery',

  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      lotteryData: [],
      photoData: null,
      allPhotoData: null,
      photoList: [],
      searchVal: '',
      curEn: '全部',
      activeName: 0,
      allEn: [
        '全部',
        'A',
        'B',
        'C',
        'D',
        'E',
        'F',
        'G',
        'H',
        'I',
        'J',
        'K',
        'L',
        'M',
        'N',
        'O',
        'P',
        'Q',
        'R',
        'S',
        'T',
        'U',
        'V',
        'W',
        'X',
        'Y',
        'Z',
      ],
      curlottery: {},
    }
  },
  computed: {

    skincss() {

      if (process.env.VUE_APP_BASE_Skin && process.env.VUE_APP_BASE_Skin != 'default') {
        return '/mobile/skin/' + process.env.VUE_APP_BASE_Skin + '.css?'+process.env.VUE_APP_VERSION
      }
      return '/mobile/skin/default.css'
    },
  },
  async mounted() {
    let { list: lotteryData } = await getLottery()
    this.lotteryData = lotteryData
    this.curlottery = lotteryData[0]
    this.getDataPhoto()
  },

  methods: {
    czTab(index, title) {
      this.curlottery = this.lotteryData[index]
      console.log(index, title)
      this.getDataPhoto()
    },
    orderTab(index, title) {
      if (title == '全部') {
        this.photoData = this.allPhotoData
      } else {
        if (this.allPhotoData[title]) {
          let obj = {}
          obj[title] = this.allPhotoData[title]
          this.photoData = obj
        } else {
          this.photoData = null
        }
      }
    },
    async getDataPhoto() {
      let uGameNo = this.curlottery.gameNo
      let uType = 37
      if (uGameNo == '90001') {
        uType = 5
      } else if (uGameNo == '90002') {
        uType = 21
      } else if (uGameNo == '90004') {
        uType = 13
      } else {
        uType = 37
      }
      let uSubType = this.$route?.query?.uYear || ''
      let { list } = await getDataPhoto({ uGameNo, uType, uSubType })
      this.photoList = list
      if (list.length < 1) return
      let filterData = list.filter((item) =>
        item.FSubType1.includes(this.searchVal)
      )
      if (filterData.length < 1) {
        this.photoData = null
        this.allPhotoData = null
      } else {
        this.sortData(filterData)
      }
    },
    // 排序
    sortData(list) {
      let fobj = {}
      let filterList = []
      list.map((item) => {
        let zm = item.FSubType1
        if (!fobj[zm]) {
          fobj[zm] = [item]
          filterList.push(item)
        }
      })
      let data = filterList.map((item) => {
        let py = pinyin(item.FSubType1.trim(), { style: 'normal' })[0].join('')
        let reg = /[a-z]/
        if (!reg.test(py)) {
          py = '#'
        }
        let obj = {
          pinyin: py,
        }
        return merge(item, obj)
      })
      let orderData = orderBy(data, ['pinyin'])
      let obj = {}
      orderData.map((item) => {
        let zm = capitalize(item.pinyin).slice(0, 1)
        if (obj[zm]) {
          obj[zm].push(item)
        } else {
          obj[zm] = [item]
        }
      })
      this.photoData = obj
      this.allPhotoData = obj
    },
    // getDataPhoto() {
    //   let uGameNo = this.$route.params.id
    //   let uType = this.$route.query.uType
    //   let uSubType = ''
    //   getDataPhoto({ uGameNo, uType, uSubType }).then((res) => {
    //     this.list = res.list
    //     this.finished = true
    //   })
    // },

    jumpDetail(data) {
      let path =
        '/gallery/detail/' +
        this.curlottery.gameNo +
        '?uFID=' +
        data.FID +
        '&uName=' +
        data.FSubType1
      this.$router.push(path)
    },
    jumpSearch(year) {
      let uType = this.$route.query.uType
      let path = '/gallery/search/' + this.$route.params.id + '?uType=' + uType
      if (year) {
        path = path + '&uYear=' + year
      }
      this.$router.push(path)
    },
    onLoad() {},
  },
}
</script>

<style lang="scss" scoped>
.limitation-width {
  background: #f2f3f5;
  .gallery-head {
    width: 100%;
    position: fixed;
    max-width: 500px;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    padding: 5px 0;
    ::v-deep .van-tabs {
      .van-tabs__nav {
        background-color: transparent;
      }
      .van-tab__text {
        position: relative;
        z-index: 9;
        font-size: 18px;
      }
      .van-tabs__line {
        width: 90px;
        height: 10px;
        bottom: 25px;
        background: unset !important;
        background-image: linear-gradient(
          to right,
          #ffffff -20%,
          #ee3737 190%
        ) !important;
        background-size: 100% 100% !important;
      }
    }
  }
  .gallery-main {
    width: 100%;
    height: calc(100% - 110px);
    position: fixed;
    top: 56px;
    left: 50%;
    overflow: auto;
    max-width: 500px;
    transform: translateX(-50%);
  }
  .gallery-search {
    padding: 0 12px;
    .search-box {
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: rgb(228 230 237);
      border-radius: 5px;
      input {
        flex: 1;
        height: 100%;
        border: none;
        outline: none;
        padding-left: 12px;
        background: rgb(228 230 237);
      }
      .gicon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 100%;
        font-size: 20px;
      }
    }
  }
  .gallery-order {
    padding: 20px 0 0;
    ::v-deep .van-tabs {
      .van-tabs__nav {
        background-color: transparent;
      }
      .van-tabs__wrap {
        height: auto;
      }
      .van-tab {
        height: 24px;
        min-width: 24px;
        padding: 0 5px;
        margin: 0;
        margin-right: 10px;
        line-height: 1;

        background-color: #e4e6ed;
        border-radius: 24px;
        &.van-tab--active {
          background-color: rgb(255 255 255);
          .van-tab__text {
            color: #f13131;
          }
        }
      }
      .van-tab__text {
        color: rgb(141 145 157);
        font-size: 12px;
      }
      .van-tabs__nav--line {
        padding-bottom: 0;
      }
      .van-tabs__line {
        display: none;
      }
    }
  }
  .gallery-cont {
    h2 {
      padding: 5px 0 0;
    }
    .gallery-list {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      .gallery-item {
        display: flex;
        align-items: center;
        width: calc(50% - 5px);
        height: 56px;
        border-radius: 5px;
        background-color: #ffffff;
        padding: 8px;
        gap: 10px;
        img {
          width: 40px;
          height: 40px;
        }
        .gallery-info {
          width: 70%;
          h4 {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-bottom: 4px;
          }
          span {
            font-size: 10px;
            color: rgb(165 167 175);
          }
        }
      }
    }
  }
}
</style>
