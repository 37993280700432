<template>
  <div class="">
    <link :href="skincss" rel="stylesheet" id="skincss"/>
    <van-nav-bar
      fixed
      title="详情"
      @click-left="goback"
      @click-right="popupShow = true"
    >
      <template #left>
        <van-icon name="arrow-left" color="#fff" size="18" />
        <span style="color: #fff">返回</span>
      </template>
    </van-nav-bar>

    <div class="cms-box">
      <div class="near">
        <div>近10期战绩</div>
        <ul class="">
          <!-- <li class="" v-for="i in 10" :key="i">中</li> -->
          <li v-for="item in cms.slice(0,10)" :key="item.FID">
            
            <template v-if="item.IsCustom">
              <template v-if="item.FOpenCode">
                    <span v-if="item.IsRight == null">?</span>
                    <span
                      v-else-if="item.IsRight"
                    >
                      中
                    </span>
                    <span v-else style="background: #000">错</span>
                  </template>
               </template>
            <!-- FRule	规则  0要参与计算  1不用 -->
            <template v-if="!item.IsCustom&&!item.FRule">
              <template v-if="item.FOpenCode">
                <!--  -->
                <span v-if="item.FType == 1 || item.FType == 2">
                  <span
                    v-if="
                      countWordsWithoutHTML(item.FContent).includes(
                        item.FOpenCode.split(',').slice(-1).toString()
                      )
                    "
                  >
                    中
                  </span>
                  <span v-else style="background: #000">错</span>
                </span>
                <!--  -->
                <span v-else-if="item.FType == 3">
                  <span
                    v-if="
                      countWordsWithoutHTML(item.FContent).includes(
                        czTool.czds2(
                          item.FOpenCode.split(',').slice(-1).toString()
                        )
                      )
                    "
                  >
                    准
                  </span>
                  <span v-else style="background: #000">错</span>
                </span>
                <!--  -->
                <span v-else-if="item.FType == 4">
                  <span
                    v-if="
                      countWordsWithoutHTML(item.FContent).includes(
                        item.FOpenCode.split(',')
                          .slice(-1)
                          .toString()
                          .slice(0, 1)
                      )
                    "
                  >
                    准
                  </span>
                  <span v-else style="background: #000">错</span>
                </span>
                <!--  -->
                <span v-else-if="item.FType == 5">
                  <span
                    v-if="
                      countWordsWithoutHTML(item.FContent).includes(
                        item.FOpenCode.split(',').slice(-1).toString().slice(-1)
                      )
                    "
                  >
                    准
                  </span>
                  <span v-else style="background: #000">错</span>
                </span>
                <!--  -->
                <span v-else-if="item.FType == 6">
                  <span
                    v-if="
                      countWordsWithoutHTML(item.FContent).includes(
                        czTool.lhchll(
                          item.FOpenCode.split(',').slice(-1).toString()
                        )
                      )
                    "
                  >
                    准
                  </span>
                  <span v-else style="background: #000">错</span>
                </span>
                <!--  -->
                <span
                  v-if="
                    item.FType == 9 ||
                    item.FType == 12 ||
                    item.FType == 13 ||
                    item.FType == 17 ||
                    item.FType == 20
                  "
                >
                  <span
                    v-if="
                      countWordsWithoutHTML(item.FContent).includes(
                        czTool.lhcSx(
                          item.FOpenCode.split(',').slice(-1).toString()
                        )
                      )
                    "
                  >
                    准
                  </span>
                  <span v-else style="background: #000">错</span>
                </span>
                <!--  -->
                <span v-else-if="item.FType == 8">
                  <span
                    v-if="
                      countWordsWithoutHTML(item.FContent).includes(
                        czTool.lhcTmYJ(item.FOpenCode)
                      )
                    "
                  >
                    准
                  </span>
                  <span v-else style="background: #000">错</span>
                </span>
                <!--  -->
                <span v-else-if="item.FType == 10">
                  <span
                    v-if="
                      countWordsWithoutHTML(item.FContent).includes(
                        czTool.lhcnnx(
                          item.FOpenCode.split(',').slice(-1).toString()
                        )
                      )
                    "
                  >
                    准
                  </span>
                  <span v-else style="background: #000">错</span>
                </span>
                <!--  -->
                <span v-else-if="item.FType == 11">
                  <span
                    v-if="
                      countWordsWithoutHTML(item.FContent).includes(
                        czTool.lhchdhx(
                          item.FOpenCode.split(',').slice(-1).toString()
                        )
                      )
                    "
                  >
                    准
                  </span>
                  <span v-else style="background: #000">错</span>
                </span>
                <!--  -->
                <span v-else-if="item.FType == 14">
                  <span
                    v-if="
                      countWordsWithoutHTML(item.FContent).includes(
                        czTool.lhctdx(
                          item.FOpenCode.split(',').slice(-1).toString()
                        )
                      )
                    "
                  >
                    准
                  </span>
                  <span v-else style="background: #000">错</span>
                </span>
                <!--  -->
                <span v-else-if="item.FType == 15">
                  <span
                    v-if="
                      countWordsWithoutHTML(item.FContent).includes(
                        czTool.lhchwx(
                          item.FOpenCode.split(',').slice(-1).toString()
                        )
                      )
                    "
                  >
                    准
                  </span>
                  <span v-else style="background: #000">错</span>
                </span>
                <!--  -->
                <span v-else-if="item.FType == 16">
                  <span
                    v-if="
                      countWordsWithoutHTML(item.FContent).includes(
                        czTool.lhccxqd(
                          item.FOpenCode.split(',').slice(-1).toString()
                        )
                      )
                    "
                  >
                    准
                  </span>
                  <span v-else style="background: #000">错</span>
                </span>
                <!-- 一句爆平特 18 有点复杂略 -->
                <span v-else-if="item.FType == 7 || item.FType == 18">
                  <span
                    v-if="
                      yjbptJs(
                        item.FOpenCode,
                        countWordsWithoutHTML(item.FContent)
                      ).bool
                    "
                  >
                    准
                  </span>
                  <span v-else style="background: #000">错</span>
                </span>
                <!--  -->
                <span v-else-if="item.FType == 19">
                  <span
                    v-if="
                      countWordsWithoutHTML(item.FContent).includes(
                        czTool.lhcddxd(
                          item.FOpenCode.split(',').slice(-1).toString()
                        )
                      )
                    "
                  >
                    准
                  </span>
                  <span v-else style="background: #000">错</span>
                </span>
                <!--  -->
                <span v-else-if="item.FType == 21">
                  <span>
                    开{{
                      czTool.lhcSx(
                        item.FOpenCode.split(',').slice(-1).toString()
                      )
                    }}
                    {{ item.FOpenCode.split(',').slice(-1).toString() }}
                  </span>
                  <span
                    v-if="
                      countWordsWithoutHTML(item.FContent).includes(
                        czTool.lhcrcc(
                          item.FOpenCode.split(',').slice(-1).toString()
                        )
                      )
                    "
                  >
                    准
                  </span>
                  <span v-else style="background: #000">错</span>
                </span>
                <!--  -->
                <span v-else-if="item.FType == 22">
                  <span
                    v-if="
                      !countWordsWithoutHTML(item.FContent).includes(
                        item.FOpenCode.split(',').slice(-1).toString()
                      )
                    "
                  >
                    准
                  </span>
                  <span v-else style="background: #000">错</span>
                </span>
                <span v-else-if="item.FType == 23">
                  <span
                    v-if="
                      yjbptJs2(
                        item.FOpenCode,
                        countWordsWithoutHTML(item.FContent)
                      ).bool
                    "
                  >
                    准
                  </span>
                  <span v-else style="background: #000">错</span>
                </span>
              </template>
              <span v-else>?</span>
            </template>
          </li>
        </ul>
      </div>
      <div class="cms-main" v-if="cms.length">
        <div
          class="cms-jump"
          :id="'tagblock-' + cms[0]?.FType"
          style="position: relative; top: -45px"
        ></div>
        <div class="cms-head">
          <!-- <span style="color: #f27d07">{{ getTitle(curActive) }}</span> -->
          <span class="title">【 {{ cms[0]?.FTypeName }} 】</span>
          <!-- <span style="color: #f27d07">{{ getTitle(curActive) }}</span> -->
        </div>
        <ul class="cms-list">
          <li v-if="cms[0].FType == 4">
            <div class="cms-rule">
              <p>头数有0.1.2.3.4.头</p>
            </div>
          </li>
          <li v-if="cms[0].FType == 5">
            <div class="cms-rule">
              <p>尾数有0.1.2.3.4.5.6.7.8.9</p>
            </div>
          </li>
          <li v-if="cms[0].FType == 8">
            <div class="cms-rule">
              <p>
                <span style="color: red">家禽：</span>
                牛、马、羊、鸡、狗、猪
              </p>
              <p>
                <span style="color: red">野兽：</span>
                鼠、虎、兔、龙、蛇、猴
              </p>
            </div>
          </li>

          <li v-if="cms[0].FType == 10">
            <div class="cms-rule">
              <p>
                <span style="color: red">男肖：</span>
                鼠、牛、虎、龙、马、猴、狗
              </p>
              <p>
                <span style="color: red">女肖：</span>
                兔、蛇、羊、鸡、猪（五宫肖）
              </p>
            </div>
          </li>
          <li v-if="cms[0].FType == 11">
            <div class="cms-rule">
              <p>
                <span style="color: red">合数小：</span>
                01-06
                <span style="color: red">合数大：</span>
                07-13
              </p>
            </div>
          </li>
          <li v-if="cms[0].FType == 15">
            <div class="cms-rule">
              <p>
                <span style="color: red">金行（10支）：</span>
                02.03.10.11.24.25.32.33.40.41
              </p>
              <p>
                <span style="color: red">木行（10支）：</span>
                06.07.14.15.22.23.36.37.44.45
              </p>
              <p>
                <span style="color: red">水行（8支）：</span>
                12.13.20.21.28.29.42.43
              </p>
              <p>
                <span style="color: red">火行（11支）：</span>
                01.08.09.16.17.30.31.38.39.46.47
              </p>
              <p>
                <span style="color: red">土行（10支）：</span>
                04.05.18.19.26.27.34.35.48.49
              </p>
            </div>
          </li>
          <li v-if="cms[0].FType == 14">
            <div class="cms-rule">
              <p>
                <span style="color: red">天肖：</span>
                兔、马、猴、猪、牛、龙
              </p>
              <p>
                <span style="color: red">地肖：</span>
                蛇、羊、鸡、狗、鼠、虎
              </p>
            </div>
          </li>
          <li v-if="cms[0].FType == 16">
            <div class="cms-rule">
              <p>【春】虎兔龙【夏】蛇马羊</p>
              <p>【秋】猴狗鸡【冬】猪鼠牛</p>
            </div>
          </li>
          <li v-if="cms[0].FType == 21">
            <div class="cms-rule">
              <p>吃肉肖【虎蛇龙狗】</p>
              <p>吃菜肖【猪鼠鸡猴】</p>
              <p>吃草肖【牛羊马兔】</p>
            </div>
          </li>
          <!--  -->
          <!--  -->
          <li v-for="item in cms" :key="item.FID">
            <!-- FRule	规则  0要参与计算  1不用 -->
            <template v-if="!item.FRule">
              <!-- <div class="qh">{{ item.FPeriods.substring(4) }}期</div> -->
              <div class="qh">{{ item.FName }}</div>
              <!-- <div class="kj">
              <span>{{ countWordsWithoutHTML(item.FContent) }}</span>
            </div> -->
              <div class="kj" v-html="item.FContent"></div>
              <div class="js" v-if="item.FOpenCode">
                <span v-if="item.IsCustom">
                  <span>
                    {{item.Result}}
                  </span>
                  <span
                    class="cms-green"
                    v-if="item.IsRight"
                  >
                    准
                  </span>
                  <span v-else class="cms-green">错</span>
                </span>
                <!--  -->
                <span v-if="item.FType == 1 || item.FType == 2">
                  <span>
                    开:{{
                      czTool.lhcSx(
                        item.FOpenCode.split(',').slice(-1).toString()
                      )
                    }}
                    {{ item.FOpenCode.split(',').slice(-1).toString() }}
                  </span>
                  <span
                    class="cms-green"
                    v-if="
                      countWordsWithoutHTML(item.FContent).includes(
                        item.FOpenCode.split(',').slice(-1).toString()
                      )
                    "
                  >
                    准
                  </span>
                  <span v-else class="cms-green">错</span>
                </span>
                <!--  -->
                <span v-else-if="item.FType == 3">
                  <span>
                    开:{{
                      czTool.lhcSx(
                        item.FOpenCode.split(',').slice(-1).toString()
                      )
                    }}
                    {{ item.FOpenCode.split(',').slice(-1).toString() }}
                  </span>

                  <span
                    class="cms-green"
                    v-if="
                      countWordsWithoutHTML(item.FContent).includes(
                        czTool.czds2(
                          item.FOpenCode.split(',').slice(-1).toString()
                        )
                      )
                    "
                  >
                    准
                  </span>
                  <span v-else class="cms-green">错</span>
                </span>
                <!--  -->
                <span v-else-if="item.FType == 4">
                  <span>
                    开:{{
                      czTool.lhcSx(
                        item.FOpenCode.split(',').slice(-1).toString()
                      )
                    }}
                    {{ item.FOpenCode.split(',').slice(-1).toString() }}
                  </span>
                  <span
                    class="cms-green"
                    v-if="
                      countWordsWithoutHTML(item.FContent).includes(
                        item.FOpenCode.split(',')
                          .slice(-1)
                          .toString()
                          .slice(0, 1)
                      )
                    "
                  >
                    准
                  </span>
                  <span v-else class="cms-green">错</span>
                </span>
                <!--  -->
                <span v-else-if="item.FType == 5">
                  <span>
                    开:{{
                      czTool.lhcSx(
                        item.FOpenCode.split(',').slice(-1).toString()
                      )
                    }}
                    {{ item.FOpenCode.split(',').slice(-1).toString() }}
                  </span>
                  <span
                    class="cms-green"
                    v-if="
                      countWordsWithoutHTML(item.FContent).includes(
                        item.FOpenCode.split(',').slice(-1).toString().slice(-1)
                      )
                    "
                  >
                    准
                  </span>
                  <span v-else class="cms-green">错</span>
                </span>
                <!--  -->
                <span v-else-if="item.FType == 6">
                  <span>
                    开:{{
                      czTool.lhchll(
                        item.FOpenCode.split(',').slice(-1).toString()
                      )
                    }}
                    <!-- <span
                    class="cms-green"
                    :style="{
                      color:
                        czTool.lhchll(
                          item.FOpenCode.split(',').slice(-1).toString()
                        ) == '绿'
                          ? 'green'
                          : 'blue',
                    }"
                  >
                    {{
                      czTool.lhchll(
                        item.FOpenCode.split(',').slice(-1).toString()
                      )
                    }}波
                  </span> -->
                    {{ item.FOpenCode.split(',').slice(-1).toString() }}
                  </span>
                  <span
                    class="cms-green"
                    v-if="
                      countWordsWithoutHTML(item.FContent).includes(
                        czTool.lhchll(
                          item.FOpenCode.split(',').slice(-1).toString()
                        )
                      )
                    "
                  >
                    准
                  </span>
                  <span v-else class="cms-green">错</span>
                </span>
                <!--  -->
                <span
                  v-if="
                    item.FType == 9 ||
                    item.FType == 12 ||
                    item.FType == 13 ||
                    item.FType == 17 ||
                    item.FType == 20
                  "
                >
                  <span>
                    开:{{
                      czTool.lhcSx(
                        item.FOpenCode.split(',').slice(-1).toString()
                      )
                    }}
                    {{ item.FOpenCode.split(',').slice(-1).toString() }}
                  </span>
                  <span
                    class="cms-green"
                    v-if="
                      countWordsWithoutHTML(item.FContent).includes(
                        czTool.lhcSx(
                          item.FOpenCode.split(',').slice(-1).toString()
                        )
                      )
                    "
                  >
                    准
                  </span>
                  <span v-else class="cms-green">错</span>
                </span>
                <!--  -->
                <span v-else-if="item.FType == 8">
                  <span>
                    开
                    {{
                      czTool.lhcSx(
                        item.FOpenCode.split(',').slice(-1).toString()
                      )
                    }}
                    {{ item.FOpenCode.split(',').slice(-1).toString() }}
                  </span>
                  <span
                    class="cms-green"
                    v-if="
                      countWordsWithoutHTML(item.FContent).includes(
                        czTool.lhcTmYJ(item.FOpenCode)
                      )
                    "
                  >
                    准
                  </span>
                  <span v-else class="cms-green">错</span>
                </span>
                <!--  -->
                <span v-else-if="item.FType == 10">
                  <span>
                    开:{{
                      czTool.lhcSx(
                        item.FOpenCode.split(',').slice(-1).toString()
                      )
                    }}
                    {{ item.FOpenCode.split(',').slice(-1).toString() }}
                  </span>
                  <span
                    class="cms-green"
                    v-if="
                      countWordsWithoutHTML(item.FContent).includes(
                        czTool.lhcnnx(
                          item.FOpenCode.split(',').slice(-1).toString()
                        )
                      )
                    "
                  >
                    准
                  </span>
                  <span v-else class="cms-green">错</span>
                </span>
                <!--  -->
                <span v-else-if="item.FType == 11">
                  <span>
                    开:{{
                      czTool.lhcSx(
                        item.FOpenCode.split(',').slice(-1).toString()
                      )
                    }}
                    {{ item.FOpenCode.split(',').slice(-1).toString() }}
                  </span>
                  <span
                    class="cms-green"
                    v-if="
                      countWordsWithoutHTML(item.FContent).includes(
                        czTool.lhchdhx(
                          item.FOpenCode.split(',').slice(-1).toString()
                        )
                      )
                    "
                  >
                    准
                  </span>
                  <span v-else class="cms-green">错</span>
                </span>
                <!--  -->
                <span v-else-if="item.FType == 14">
                  <span>
                    开:{{
                      czTool.lhcSx(
                        item.FOpenCode.split(',').slice(-1).toString()
                      )
                    }}
                    {{ item.FOpenCode.split(',').slice(-1).toString() }}
                  </span>
                  <span
                    class="cms-green"
                    v-if="
                      countWordsWithoutHTML(item.FContent).includes(
                        czTool.lhctdx(
                          item.FOpenCode.split(',').slice(-1).toString()
                        )
                      )
                    "
                  >
                    准
                  </span>
                  <span v-else class="cms-green">错</span>
                </span>
                <!--  -->
                <span v-else-if="item.FType == 15">
                  <span>
                    开:{{
                      czTool.lhcSx(
                        item.FOpenCode.split(',').slice(-1).toString()
                      )
                    }}
                    {{ item.FOpenCode.split(',').slice(-1).toString() }}
                  </span>
                  <span
                    class="cms-green"
                    v-if="
                      countWordsWithoutHTML(item.FContent).includes(
                        czTool.lhchwx(
                          item.FOpenCode.split(',').slice(-1).toString()
                        )
                      )
                    "
                  >
                    准
                  </span>
                  <span v-else class="cms-green">错</span>
                </span>
                <!--  -->
                <span v-else-if="item.FType == 16">
                  <span>
                    开:{{
                      czTool.lhcSx(
                        item.FOpenCode.split(',').slice(-1).toString()
                      )
                    }}
                    {{ item.FOpenCode.split(',').slice(-1).toString() }}
                  </span>
                  <span
                    class="cms-green"
                    v-if="
                      countWordsWithoutHTML(item.FContent).includes(
                        czTool.lhccxqd(
                          item.FOpenCode.split(',').slice(-1).toString()
                        )
                      )
                    "
                  >
                    准
                  </span>
                  <span v-else class="cms-green">错</span>
                </span>
                <!-- 一句爆平特 18 有点复杂略 -->
                <span v-else-if="item.FType == 7 || item.FType == 18">
                  <span>
                    开:
                    {{
                      yjbptJs(
                        item.FOpenCode,
                        countWordsWithoutHTML(item.FContent)
                      ).sx
                    }}
                    {{
                      yjbptJs(
                        item.FOpenCode,
                        countWordsWithoutHTML(item.FContent)
                      ).num
                    }}
                  </span>
                  <span class="cms-green">
                    {{
                      yjbptJs(
                        item.FOpenCode,
                        countWordsWithoutHTML(item.FContent)
                      ).desc
                    }}
                  </span>
                </span>
                <!--  -->
                <span v-else-if="item.FType == 19">
                  <span>
                    开:{{
                      czTool.lhcSx(
                        item.FOpenCode.split(',').slice(-1).toString()
                      )
                    }}
                    {{ item.FOpenCode.split(',').slice(-1).toString() }}
                  </span>
                  <span
                    class="cms-green"
                    v-if="
                      countWordsWithoutHTML(item.FContent).includes(
                        czTool.lhcddxd(
                          item.FOpenCode.split(',').slice(-1).toString()
                        )
                      )
                    "
                  >
                    准
                  </span>
                  <span v-else class="cms-green">错</span>
                </span>
                <!--  -->
                <span v-else-if="item.FType == 21">
                  <span>
                    开:{{
                      czTool.lhcSx(
                        item.FOpenCode.split(',').slice(-1).toString()
                      )
                    }}
                    {{ item.FOpenCode.split(',').slice(-1).toString() }}
                  </span>
                  <span
                    class="cms-green"
                    v-if="
                      countWordsWithoutHTML(item.FContent).includes(
                        czTool.lhcrcc(
                          item.FOpenCode.split(',').slice(-1).toString()
                        )
                      )
                    "
                  >
                    准
                  </span>
                  <span v-else class="cms-green">错</span>
                </span>
                <!--  -->
                <span v-else-if="item.FType == 22">
                  <span>
                    开:{{
                      czTool.lhcSx(
                        item.FOpenCode.split(',').slice(-1).toString()
                      )
                    }}
                    {{ item.FOpenCode.split(',').slice(-1).toString() }}
                  </span>
                  <span
                    class="cms-green"
                    v-if="
                      !countWordsWithoutHTML(item.FContent).includes(
                        item.FOpenCode.split(',').slice(-1).toString()
                      )
                    "
                  >
                    准
                  </span>
                  <span v-else class="cms-green">错</span>
                </span>
                <span v-else-if="item.FType == 23">
                  <span>开:</span>
                  <span class="cms-green">
                    {{
                      yjbptJs2(
                        item.FOpenCode,
                        countWordsWithoutHTML(item.FContent)
                      ).len
                    }}个
                  </span>
                </span>
              </div>
              <div class="js" v-else>
                <span v-if="item.FType == 23">
                  开:?
                  <span class="cms-green">个</span>
                </span>
                <span v-else>
                  开:? 00
                  <span class="cms-green">准</span>
                </span>
              </div>
            </template>
            <template v-else-if="!item.IsCustom">
              <div class="kj" v-html="item.FContent"></div>
            </template>
          </li>
        </ul>
        <div class="cms-foot">
          <!-- <span @click="jumpCmsDetail(cms[0]?.FType)">查看往期记录 ></span> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import czTool from '@/utils/cztool'
import { getCMSList } from '@/api/lottery'
export default {
  data() {
    return {
      cms: [],
    }
  },
  methods: {
    yjbptJs(numStr, content) {
      let arr = numStr.split(',')
      let relust = {
        sx: this.czTool.lhcSx(arr.slice(-1).toString()),
        num: arr.slice(-1).toString(),
        desc: '错',
        bool: false,
      }

      for (let i = 0; i < arr.length; i++) {
        let sx = this.czTool.lhcSx(arr[i])
        if (content.includes(sx)) {
          relust.sx = sx
          relust.num = arr[i]
          relust.desc = '准'
          relust.bool = true
          break
        }
      }
      return relust
    },
    yjbptJs2(numStr, content) {
      let arr = numStr.split(',')
      let relust = {
        sx: this.czTool.lhcSx(arr.slice(-1).toString()),
        num: arr.slice(-1).toString(),
        desc: '错',
        bool: false,
        len: 0,
      }

      for (let i = 0; i < arr.length; i++) {
        let sx = this.czTool.lhcSx(arr[i])
        if (content.includes(sx)) {
          // relust.sx = sx
          relust.bool = true
          relust.desc = '准'
          relust.len++
        }
      }
      return relust
    },
    countWordsWithoutHTML(text) {
      // 使用正则表达式移除HTML标签回车空格
      let cleanText = text.replace(/<[^>]*>/g, '').replace(/[\n\s]+/g, '')

      return cleanText
    },
  },
  computed: {
    czTool() {
      return czTool
    },
    skincss() {
      if (process.env.VUE_APP_BASE_Skin && process.env.VUE_APP_BASE_Skin != 'default') {
        return '/mobile/skin/' + process.env.VUE_APP_BASE_Skin + '.css'
      }
      return '/mobile/skin/default.css'
    },
  },
  created() {
    let params = {
      uGameNo: this.$route.params.id,
      uType: this.$route.query.uType,
      PageSize: 999,
      PageNo: 1,
    }
    getCMSList(params).then((res) => {
      this.cms = res.list
    })
  },
}
</script>

<style lang="scss" scoped>
.cms-box {
  padding: 5px;
  padding-top: 50px;
  .cms-main {
    width: 100%;
    overflow: hidden;
    --template-custom-color1: #e76f5160;
    --template-custom-color2: #e76f5121;
    --template-custom-borderColor: rgba(111, 81, 96, 0.3);
    border: solid 1px rgba(111, 81, 96, 0.3);
    border-radius: 10px;
    .cms-head {
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      border-bottom: solid 1px rgba(111, 81, 96, 0.3);
      background-image: linear-gradient(
        rgba(231, 111, 81, 0.376),
        rgba(231, 111, 81, 0.13)
      );
      display: flex;
      height: 40px;
      justify-content: center;
      align-items: center;
      gap: 5px;
      font-size: 18px;
      font-weight: 700;
      .title {
        font-size: 22px;
      }
    }
    .cms-list {
      li {
        display: flex;
        align-items: center;

        font-size: 16px;
        border-bottom: 0.5px solid rgba(111, 81, 96, 0.3);
        background: #fcece8;
        div {
          font-weight: 700;
          padding: 10px 5px;
        }
        .qh {
          min-width: 70px;
          text-align: center;
        }
        .kj {
          flex: 1;
          word-break: break-all;
          background: #fff;

          // word-wrap: break-word;
          // border-left: 1px solid rgba(111, 81, 96, 0.3);
          // border-right: 1px solid rgba(111, 81, 96, 0.3);
        }
        .js {
          width: 100px;
          color: red;
          text-align: center;
        }
      }
    }
    .cms-foot {
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
      display: flex;
      height: 30px;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px;
      color: rgba(231, 111, 81, 0.376);
      background: rgba(231, 111, 81, 0.13);
      font-weight: 600px;
      font-size: 14px;
    }
    .cms-green {
      color: #43a643;
    }
  }
  .near {
    padding: 10px;
    div {
      margin-bottom: 10px;
    }
    ul {
      display: flex;
      gap: 10px;
      // justify-content: space-between;

      li {
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 0.6rem;
          height: 0.6rem;
          border-radius: 50%;
          color: #fff;
          background: #ec133a;
          padding-bottom: 1px;
        }
      }
    }
  }
}
</style>