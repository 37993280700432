<template>
  <div class="home-container">    
    <link :href="skincss" rel="stylesheet" id="skincss"/>
    <div class="home-head">
      <div class="head-cont">
        <div class="head-img">
          <img :src="getLogo()" alt="" />
        </div>
        <div class="head-btns" v-if="!username&&isShowBuy">
          <router-link to="/login">登录</router-link>
          <router-link to="/register">注册</router-link>
        </div>
      </div>
    </div>
    <div style="padding: 2px 0"></div>
    <van-notice-bar scrollable color="#ffffff">
      <div class="noticebar-cont" v-if="noticeData.length">
        <span>{{ countWordsWithoutHTML(noticeData[0]?.conTents) }}</span>
      </div>
    </van-notice-bar>

    <div class="lot-hotGame">
      <ul class="lot-list">
        <li v-for="(item, i) in hotGameData" :key="i" @click="jumpWfPage(item)">
          <img :src="'/images/lottery/round/' + item.gameNo + '.png'" alt="" />
          <p>{{ item.gameName }}</p>
        </li>
      </ul>
    </div>

    <!-- 主页广告1 彩种上面位置 -->
    <ul class="urls-wrap">
      <li v-for="item in gg1Data" :key="item.id">
        <a :href="item.Furl ? item.Furl : 'javascript:;'" target="_blank">
          <div class="headPath" style="padding: 3px">
            <img :src="getImgPath(item.Fimg)" alt="" />
          </div>
        </a>
      </li>
    </ul>

    <div class="home-tabs">
      <span
        v-for="(item, i) in lotteryData"
        :class="{ active: curActive == item.gameNo }"
        @click="onTab(item.gameNo)"
        :key="i"
      >
        <b :gameNo="item.gameNo" id="gametitle">{{ item.gameName }}</b>
        <strong v-if="item.gameNo == '90001' && curActive == item.gameNo">
          <!-- {{ hknextOpenTime | formatTime('MM') }}月{{
            hknextOpenTime | formatTime('DD')
          }}日开奖 -->
          {{ hknextOpenTime | formatTime('HH:mm') }}直播开奖
        </strong>
        <strong v-else-if="item.gameNo == '90002' && curActive == item.gameNo">
          <!-- {{ xjbnextOpenTime | formatTime('MM') }}月{{
            xjbnextOpenTime | formatTime('DD')
          }}日开奖 -->
          {{ xjbnextOpenTime | formatTime('HH:mm') }}直播开奖
        </strong>
        <strong v-else-if="item.gameNo == '90003' && curActive == item.gameNo">
          <!-- {{ twnextOpenTime | formatTime('MM') }}月{{
            twnextOpenTime | formatTime('DD')
          }}日开奖 -->
          {{ twnextOpenTime | formatTime('HH:mm') }}直播开奖
        </strong>
        <strong v-else-if="item.gameNo == '90005' && curActive == item.gameNo">
          <!-- {{ xamnextOpenTime | formatTime('MM') }}月{{
            xamnextOpenTime | formatTime('DD')
          }}日开奖 -->
          {{ xamnextOpenTime | formatTime('HH:mm') }}直播开奖
        </strong>
        <strong v-else-if="item.gameNo == '90006' && curActive == item.gameNo">
          <!-- {{ xxamnextOpenTime | formatTime('MM') }}月{{
            xxamnextOpenTime | formatTime('DD')
          }}日开奖 -->
          {{ xxamnextOpenTime | formatTime('HH:mm') }}直播开奖
        </strong>
        <strong v-else-if="curActive == item.gameNo">
          <!-- {{ amnextOpenTime | formatTime('MM') }}月{{
            amnextOpenTime | formatTime('DD')
          }}日开奖 -->
          {{ amnextOpenTime | formatTime('HH:mm') }}直播开奖
        </strong>
      </span>
    </div>
    <Draw
      ref="drawRef"
      v-if="lotteryData && lotteryData.length > 0"
      :is-count-down="true"
      @shuaxin="shuaxin"
    />
    <br />
    <!-- 主页广告2 彩种下面位置 -->
    <ul class="urls-wrap">
      <li v-for="item in gg2Data" :key="item.id">
        <a :href="item.Furl ? item.Furl : 'javascript:;'" target="_blank">
          <div class="headPath" style="padding: 3px">
            <img :src="getImgPath(item.Fimg)" alt="" />
          </div>
        </a>
      </li>
    </ul>

    <div class="home-tabs home-tabs2">
      <span
        v-for="(item, i) in lotteryData"
        :class="{ active: curActive == item.gameNo }"
        @click="onTab(item.gameNo)"
        :key="i"
      >
        <b>{{ item.gameName }}</b>
        <img
          style="
            position: absolute;
            width: 1.2rem;
            top: -0.3rem;
            left: 50%;
            transform: translateX(-50%);
          "
          src="@/assets/images/ads-news-22da2516.gif"
          alt=""
        />
        <!-- <strong v-if="item.gameNo == '90001'">
          {{ hknextOpenTime | formatTime('MM') }}月{{
            hknextOpenTime | formatTime('DD')
          }}日开奖
        </strong>
        <strong v-else-if="item.gameNo == '90002'">
          {{ xjbnextOpenTime | formatTime('MM') }}月{{
            xjbnextOpenTime | formatTime('DD')
          }}日开奖
        </strong>
        <strong v-else-if="item.gameNo == '90003'">
          {{ twnextOpenTime | formatTime('MM') }}月{{
            twnextOpenTime | formatTime('DD')
          }}日开奖
        </strong>
        <strong v-else-if="item.gameNo == '90005'">
          {{ xamnextOpenTime | formatTime('MM') }}月{{
            xamnextOpenTime | formatTime('DD')
          }}日开奖
        </strong>
        <strong v-else-if="item.gameNo == '90006'">
          {{ xxamnextOpenTime | formatTime('MM') }}月{{
            xxamnextOpenTime | formatTime('DD')
          }}日开奖
        </strong>
        <strong v-else>
          {{ amnextOpenTime | formatTime('MM') }}月{{
            amnextOpenTime | formatTime('DD')
          }}日开奖
        </strong> -->
      </span>
    </div>
    <ul class="tag-list">
      <li class="tag-item active">
        <a href="javascript:;">全部</a>
      </li>
      <li class="tag-item" v-for="item in tagList" :key="item.FID">
        <a href="javascript:;" @click="jumpToTag(item.FID)">
          {{ item.FName }}
        </a>
      </li>
      <li class="tag-item" @click="getMore" v-show="tagList.length < 10">
        <a href="javascript:;">更多</a>
      </li>
      <div style="width: 30px"></div>
      <li class="tag-item" @click="getMore(1)" v-show="tagList.length > 10">
        <a href="javascript:;">收起</a>
      </li>
    </ul>

    <!-- <a href="javascript:;" class="home-bannerFriend">
      <img src="@/assets/images/home/639445b48161f.gif" alt="" />
    </a> -->

    <!--     
    <ul class="home-other">
      <li v-for="(item, i) in homeOther" :key="i">
        <a href="javascript:;" @click="jump(item.to, item.FGameNo, item.FID)">
          <i :style="'background-image:url(' + getImgPath(item.FImg) + ')'"></i>
          <p>
            <strong>{{ item.FName }}</strong>
            <span>{{ item.FSubName }}</span>
          </p>
          <img
            v-if="item.FState"
            :class="item.FState"
            :src="require('../../assets/images/home/' + item.FState + '.png')"
            alt=""
          />
        </a>
      </li>
    </ul> -->

    <van-list
      v-model="loading"
      :finished="finished"
      :immediate-check="false"
      @load="onLoad"
    >
      <template v-if="dataList && dataList.length > 0">
        <!-- <div v-for="(item, i) in dataList" :key="i">
          <div class="cont2-box">
            <h4 class="title">{{ item.FTypeName }}</h4>
            <template v-if="item.children">
              <template v-for="(child, s2) in item.children">
                <div :key="s2" v-html="child.FContent"></div>
              </template>
            </template>
          </div>
        </div> -->
        <div class="cms-box" v-for="(item, i) in dataList" :key="i">
          <div class="cms-main">
            <div class="cms-head">
              <span class="title">{{ item.FTypeName }}</span>
            </div>
            <template v-if="item.children">
              <template v-for="(child, s2) in item.children">
                <div :key="s2" v-html="child.FContent"></div>
              </template>
            </template>
            <!-- <div class="cms-foot"></div> -->
          </div>
        </div>
      </template>
    </van-list>
    <!-- 版权广告区-->

    <!-- 图库 -->
    <div class="cms-box" v-if="photoList.length">
      <div class="cms-main">
        <div class="cms-head">
          <span>{{ getTitle(curActive) }}</span>
          <span class="title">【 {{ photoList[0]?.FSubType1 }} 】</span>
        </div>
        <div class="cms-cont">
          <div class="cms-dq">
            <span
              v-for="(item, i) in photoList"
              :key="i"
              :class="{ active: i == curPhoto }"
              @click="curPhoto = i"
            >
              {{ item.FName }}
            </span>
          </div>
          <ul class="cms-list">
            <li
              class="cms-hide"
              v-for="(item, i) in photoList"
              :key="i"
              :class="{ active: i == curPhoto }"
            >
              <img :src="item.FImg" alt="" />
            </li>
          </ul>
        </div>
        <div class="cms-foot">
          <span
            @click="jumpPhotoPage(photoList[0].FGameNo, photoList[0].FType)"
          >
            查看更多 >
          </span>
          <span class="cms-sq" @click="onTsClick($event)">
            <span>收起</span>
            <van-icon name="back-top" />
          </span>
        </div>
      </div>
    </div>
        <!-- 图库2 -->
       <div class="cms-box" v-if="photoList2.length">
        <div class="cms-main">
          <div class="cms-head">
            <span>{{ getTitle(curActive) }}</span>
            <span class="title">【 {{ photoList2[0]?.FSubType1 }} 】</span>
          </div>
          <div class="cms-cont">
            <div class="cms-dq">
              <span
                v-for="(item, i) in photoList2"
                :key="i"
                :class="{ active: i == curPhoto }"
                @click="curPhoto = i"
              >
                {{ item.FName }}
              </span>
            </div>
            <ul class="cms-list">
              <li
                class="cms-hide"
                v-for="(item, i) in photoList2"
                :key="i"
                :class="{ active: i == curPhoto }"
              >
                <img :src="item.FImg" alt="" />
              </li>
            </ul>
          </div>
          <div class="cms-foot">
            <span
              @click="jumpPhotoPage(photoList2[0].FGameNo, photoList2[0].FType)"
            >
              查看更多 >
            </span>
            <span class="cms-sq" @click="onTsClick($event)">
              <span>收起</span>
              <van-icon name="back-top" />
            </span>
          </div>
        </div>
      </div>
        <!-- 图库3 -->
       <div class="cms-box" v-if="photoList3.length">
        <div class="cms-main">
          <div class="cms-head">
            <span>{{ getTitle(curActive) }}</span>
            <span class="title">【 {{ photoList3[0]?.FSubType1 }} 】</span>
          </div>
          <div class="cms-cont">
            <div class="cms-dq">
              <span
                v-for="(item, i) in photoList3"
                :key="i"
                :class="{ active: i == curPhoto }"
                @click="curPhoto = i"
              >
                {{ item.FName }}
              </span>
            </div>
            <ul class="cms-list">
              <li
                class="cms-hide"
                v-for="(item, i) in photoList3"
                :key="i"
                :class="{ active: i == curPhoto }"
              >
                <img :src="item.FImg" alt="" />
              </li>
            </ul>
          </div>
          <div class="cms-foot">
            <span
              @click="jumpPhotoPage(photoList3[0].FGameNo, photoList3[0].FType)"
            >
              查看更多 >
            </span>
            <span class="cms-sq" @click="onTsClick($event)">
              <span>收起</span>
              <van-icon name="back-top" />
            </span>
          </div>
        </div>
      </div>
        <!-- 图库4 -->
       <div class="cms-box" v-if="photoList4.length">
        <div class="cms-main">
          <div class="cms-head">
            <span>{{ getTitle(curActive) }}</span>
            <span class="title">【 {{ photoList4[0]?.FSubType1 }} 】</span>
          </div>
          <div class="cms-cont">
            <div class="cms-dq">
              <span
                v-for="(item, i) in photoList4"
                :key="i"
                :class="{ active: i == curPhoto }"
                @click="curPhoto = i"
              >
                {{ item.FName }}
              </span>
            </div>
            <ul class="cms-list">
              <li
                class="cms-hide"
                v-for="(item, i) in photoList4"
                :key="i"
                :class="{ active: i == curPhoto }"
              >
                <img :src="item.FImg" alt="" />
              </li>
            </ul>
          </div>
          <div class="cms-foot">
            <span
              @click="jumpPhotoPage(photoList4[0].FGameNo, photoList4[0].FType)"
            >
              查看更多 >
            </span>
            <span class="cms-sq" @click="onTsClick($event)">
              <span>收起</span>
              <van-icon name="back-top" />
            </span>
          </div>
        </div>
      </div>
        <!-- 图库5 -->
       <div class="cms-box" v-if="photoList5.length">
        <div class="cms-main">
          <div class="cms-head">
            <span>{{ getTitle(curActive) }}</span>
            <span class="title">【 {{ photoList5[0]?.FSubType1 }} 】</span>
          </div>
          <div class="cms-cont">
            <div class="cms-dq">
              <span
                v-for="(item, i) in photoList5"
                :key="i"
                :class="{ active: i == curPhoto }"
                @click="curPhoto = i"
              >
                {{ item.FName }}
              </span>
            </div>
            <ul class="cms-list">
              <li
                class="cms-hide"
                v-for="(item, i) in photoList5"
                :key="i"
                :class="{ active: i == curPhoto }"
              >
                <img :src="item.FImg" alt="" />
              </li>
            </ul>
          </div>
          <div class="cms-foot">
            <span
              @click="jumpPhotoPage(photoList5[0].FGameNo, photoList5[0].FType)"
            >
              查看更多 >
            </span>
            <span class="cms-sq" @click="onTsClick($event)">
              <span>收起</span>
              <van-icon name="back-top" />
            </span>
          </div>
        </div>
      </div>
        <!-- 图库6 -->
       <div class="cms-box" v-if="photoList6.length">
        <div class="cms-main">
          <div class="cms-head">
            <span>{{ getTitle(curActive) }}</span>
            <span class="title">【 {{ photoList6[0]?.FSubType1 }} 】</span>
          </div>
          <div class="cms-cont">
            <div class="cms-dq">
              <span
                v-for="(item, i) in photoList6"
                :key="i"
                :class="{ active: i == curPhoto }"
                @click="curPhoto = i"
              >
                {{ item.FName }}
              </span>
            </div>
            <ul class="cms-list">
              <li
                class="cms-hide"
                v-for="(item, i) in photoList6"
                :key="i"
                :class="{ active: i == curPhoto }"
              >
                <img :src="item.FImg" alt="" />
              </li>
            </ul>
          </div>
          <div class="cms-foot">
            <span
              @click="jumpPhotoPage(photoList6[0].FGameNo, photoList6[0].FType)"
            >
              查看更多 >
            </span>
            <span class="cms-sq" @click="onTsClick($event)">
              <span>收起</span>
              <van-icon name="back-top" />
            </span>
          </div>
        </div>
      </div>
        <!-- 图库2 -->
       <div class="cms-box" v-if="photoList6.length">
        <div class="cms-main">
          <div class="cms-head">
            <span>{{ getTitle(curActive) }}</span>
            <span class="title">【 {{ photoList6[0]?.FSubType1 }} 】</span>
          </div>
          <div class="cms-cont">
            <div class="cms-dq">
              <span
                v-for="(item, i) in photoList6"
                :key="i"
                :class="{ active: i == curPhoto }"
                @click="curPhoto = i"
              >
                {{ item.FName }}
              </span>
            </div>
            <ul class="cms-list">
              <li
                class="cms-hide"
                v-for="(item, i) in photoList6"
                :key="i"
                :class="{ active: i == curPhoto }"
              >
                <img :src="item.FImg" alt="" />
              </li>
            </ul>
          </div>
          <div class="cms-foot">
            <span
              @click="jumpPhotoPage(photoList6[0].FGameNo, photoList6[0].FType)"
            >
              查看更多 >
            </span>
            <span class="cms-sq" @click="onTsClick($event)">
              <span>收起</span>
              <van-icon name="back-top" />
            </span>
          </div>
        </div>
      </div>
        <!-- 图库7 -->
       <div class="cms-box" v-if="photoList7.length">
        <div class="cms-main">
          <div class="cms-head">
            <span>{{ getTitle(curActive) }}</span>
            <span class="title">【 {{ photoList7[0]?.FSubType1 }} 】</span>
          </div>
          <div class="cms-cont">
            <div class="cms-dq">
              <span
                v-for="(item, i) in photoList7"
                :key="i"
                :class="{ active: i == curPhoto }"
                @click="curPhoto = i"
              >
                {{ item.FName }}
              </span>
            </div>
            <ul class="cms-list">
              <li
                class="cms-hide"
                v-for="(item, i) in photoList7"
                :key="i"
                :class="{ active: i == curPhoto }"
              >
                <img :src="item.FImg" alt="" />
              </li>
            </ul>
          </div>
          <div class="cms-foot">
            <span
              @click="jumpPhotoPage(photoList7[0].FGameNo, photoList7[0].FType)"
            >
              查看更多 >
            </span>
            <span class="cms-sq" @click="onTsClick($event)">
              <span>收起</span>
              <van-icon name="back-top" />
            </span>
          </div>
        </div>
      </div>
        <!-- 图库8 -->
       <div class="cms-box" v-if="photoList8.length">
        <div class="cms-main">
          <div class="cms-head">
            <span>{{ getTitle(curActive) }}</span>
            <span class="title">【 {{ photoList8[0]?.FSubType1 }} 】</span>
          </div>
          <div class="cms-cont">
            <div class="cms-dq">
              <span
                v-for="(item, i) in photoList8"
                :key="i"
                :class="{ active: i == curPhoto }"
                @click="curPhoto = i"
              >
                {{ item.FName }}
              </span>
            </div>
            <ul class="cms-list">
              <li
                class="cms-hide"
                v-for="(item, i) in photoList8"
                :key="i"
                :class="{ active: i == curPhoto }"
              >
                <img :src="item.FImg" alt="" />
              </li>
            </ul>
          </div>
          <div class="cms-foot">
            <span
              @click="jumpPhotoPage(photoList8[0].FGameNo, photoList8[0].FType)"
            >
              查看更多 >
            </span>
            <span class="cms-sq" @click="onTsClick($event)">
              <span>收起</span>
              <van-icon name="back-top" />
            </span>
          </div>
        </div>
      </div>
        <!-- 图库9 -->
       <div class="cms-box" v-if="photoList9.length">
        <div class="cms-main">
          <div class="cms-head">
            <span>{{ getTitle(curActive) }}</span>
            <span class="title">【 {{ photoList9[0]?.FSubType1 }} 】</span>
          </div>
          <div class="cms-cont">
            <div class="cms-dq">
              <span
                v-for="(item, i) in photoList9"
                :key="i"
                :class="{ active: i == curPhoto }"
                @click="curPhoto = i"
              >
                {{ item.FName }}
              </span>
            </div>
            <ul class="cms-list">
              <li
                class="cms-hide"
                v-for="(item, i) in photoList9"
                :key="i"
                :class="{ active: i == curPhoto }"
              >
                <img :src="item.FImg" alt="" />
              </li>
            </ul>
          </div>
          <div class="cms-foot">
            <span
              @click="jumpPhotoPage(photoList9[0].FGameNo, photoList9[0].FType)"
            >
              查看更多 >
            </span>
            <span class="cms-sq" @click="onTsClick($event)">
              <span>收起</span>
              <van-icon name="back-top" />
            </span>
          </div>
        </div>
      </div>
        <!-- 图库10 -->
       <div class="cms-box" v-if="photoList10.length">
        <div class="cms-main">
          <div class="cms-head">
            <span>{{ getTitle(curActive) }}</span>
            <span class="title">【 {{ photoList10[0]?.FSubType1 }} 】</span>
          </div>
          <div class="cms-cont">
            <div class="cms-dq">
              <span
                v-for="(item, i) in photoList10"
                :key="i"
                :class="{ active: i == curPhoto }"
                @click="curPhoto = i"
              >
                {{ item.FName }}
              </span>
            </div>
            <ul class="cms-list">
              <li
                class="cms-hide"
                v-for="(item, i) in photoList10"
                :key="i"
                :class="{ active: i == curPhoto }"
              >
                <img :src="item.FImg" alt="" />
              </li>
            </ul>
          </div>
          <div class="cms-foot">
            <span
              @click="jumpPhotoPage(photoList10[0].FGameNo, photoList10[0].FType)"
            >
              查看更多 >
            </span>
            <span class="cms-sq" @click="onTsClick($event)">
              <span>收起</span>
              <van-icon name="back-top" />
            </span>
          </div>
        </div>
      </div>
        <!-- 图库11 -->
       <div class="cms-box" v-if="photoList11.length">
        <div class="cms-main">
          <div class="cms-head">
            <span>{{ getTitle(curActive) }}</span>
            <span class="title">【 {{ photoList11[0]?.FSubType1 }} 】</span>
          </div>
          <div class="cms-cont">
            <div class="cms-dq">
              <span
                v-for="(item, i) in photoList11"
                :key="i"
                :class="{ active: i == curPhoto }"
                @click="curPhoto = i"
              >
                {{ item.FName }}
              </span>
            </div>
            <ul class="cms-list">
              <li
                class="cms-hide"
                v-for="(item, i) in photoList11"
                :key="i"
                :class="{ active: i == curPhoto }"
              >
                <img :src="item.FImg" alt="" />
              </li>
            </ul>
          </div>
          <div class="cms-foot">
            <span
              @click="jumpPhotoPage(photoList11[0].FGameNo, photoList11[0].FType)"
            >
              查看更多 >
            </span>
            <span class="cms-sq" @click="onTsClick($event)">
              <span>收起</span>
              <van-icon name="back-top" />
            </span>
          </div>
        </div>
      </div>
      
        <!-- 图库12 -->
        <div class="cms-box" v-if="photoList12.length">
        <div class="cms-main">
          <div class="cms-head">
            <span>{{ getTitle(curActive) }}</span>
            <span class="title">【 {{ photoList12[0]?.FSubType1 }} 】</span>
          </div>
          <div class="cms-cont">
            <div class="cms-dq">
              <span
                v-for="(item, i) in photoList12"
                :key="i"
                :class="{ active: i == curPhoto }"
                @click="curPhoto = i"
              >
                {{ item.FName }}
              </span>
            </div>
            <ul class="cms-list">
              <li
                class="cms-hide"
                v-for="(item, i) in photoList12"
                :key="i"
                :class="{ active: i == curPhoto }"
              >
                <img :src="item.FImg" alt="" />
              </li>
            </ul>
          </div>
          <div class="cms-foot">
            <span
              @click="jumpPhotoPage(photoList12[0].FGameNo, photoList12[0].FType)"
            >
              查看更多 >
            </span>
            <span class="cms-sq" @click="onTsClick($event)">
              <span>收起</span>
              <van-icon name="back-top" />
            </span>
          </div>
        </div>
      </div>
      
        <!-- 图库13 -->
        <div class="cms-box" v-if="photoList13.length">
        <div class="cms-main">
          <div class="cms-head">
            <span>{{ getTitle(curActive) }}</span>
            <span class="title">【 {{ photoList13[0]?.FSubType1 }} 】</span>
          </div>
          <div class="cms-cont">
            <div class="cms-dq">
              <span
                v-for="(item, i) in photoList13"
                :key="i"
                :class="{ active: i == curPhoto }"
                @click="curPhoto = i"
              >
                {{ item.FName }}
              </span>
            </div>
            <ul class="cms-list">
              <li
                class="cms-hide"
                v-for="(item, i) in photoList13"
                :key="i"
                :class="{ active: i == curPhoto }"
              >
                <img :src="item.FImg" alt="" />
              </li>
            </ul>
          </div>
          <div class="cms-foot">
            <span
              @click="jumpPhotoPage(photoList13[0].FGameNo, photoList13[0].FType)"
            >
              查看更多 >
            </span>
            <span class="cms-sq" @click="onTsClick($event)">
              <span>收起</span>
              <van-icon name="back-top" />
            </span>
          </div>
        </div>
      </div>
      
        <!-- 图库14 -->
        <div class="cms-box" v-if="photoList14.length">
        <div class="cms-main">
          <div class="cms-head">
            <span>{{ getTitle(curActive) }}</span>
            <span class="title">【 {{ photoList14[0]?.FSubType1 }} 】</span>
          </div>
          <div class="cms-cont">
            <div class="cms-dq">
              <span
                v-for="(item, i) in photoList14"
                :key="i"
                :class="{ active: i == curPhoto }"
                @click="curPhoto = i"
              >
                {{ item.FName }}
              </span>
            </div>
            <ul class="cms-list">
              <li
                class="cms-hide"
                v-for="(item, i) in photoList14"
                :key="i"
                :class="{ active: i == curPhoto }"
              >
                <img :src="item.FImg" alt="" />
              </li>
            </ul>
          </div>
          <div class="cms-foot">
            <span
              @click="jumpPhotoPage(photoList14[0].FGameNo, photoList14[0].FType)"
            >
              查看更多 >
            </span>
            <span class="cms-sq" @click="onTsClick($event)">
              <span>收起</span>
              <van-icon name="back-top" />
            </span>
          </div>
        </div>
      </div>
      
        <!-- 图库15 -->
        <div class="cms-box" v-if="photoList15.length">
        <div class="cms-main">
          <div class="cms-head">
            <span>{{ getTitle(curActive) }}</span>
            <span class="title">【 {{ photoList15[0]?.FSubType1 }} 】</span>
          </div>
          <div class="cms-cont">
            <div class="cms-dq">
              <span
                v-for="(item, i) in photoList15"
                :key="i"
                :class="{ active: i == curPhoto }"
                @click="curPhoto = i"
              >
                {{ item.FName }}
              </span>
            </div>
            <ul class="cms-list">
              <li
                class="cms-hide"
                v-for="(item, i) in photoList15"
                :key="i"
                :class="{ active: i == curPhoto }"
              >
                <img :src="item.FImg" alt="" />
              </li>
            </ul>
          </div>
          <div class="cms-foot">
            <span
              @click="jumpPhotoPage(photoList15[0].FGameNo, photoList15[0].FType)"
            >
              查看更多 >
            </span>
            <span class="cms-sq" @click="onTsClick($event)">
              <span>收起</span>
              <van-icon name="back-top" />
            </span>
          </div>
        </div>
      </div>
      
        <!-- 图库16 -->
        <div class="cms-box" v-if="photoList16.length">
        <div class="cms-main">
          <div class="cms-head">
            <span>{{ getTitle(curActive) }}</span>
            <span class="title">【 {{ photoList16[0]?.FSubType1 }} 】</span>
          </div>
          <div class="cms-cont">
            <div class="cms-dq">
              <span
                v-for="(item, i) in photoList16"
                :key="i"
                :class="{ active: i == curPhoto }"
                @click="curPhoto = i"
              >
                {{ item.FName }}
              </span>
            </div>
            <ul class="cms-list">
              <li
                class="cms-hide"
                v-for="(item, i) in photoList16"
                :key="i"
                :class="{ active: i == curPhoto }"
              >
                <img :src="item.FImg" alt="" />
              </li>
            </ul>
          </div>
          <div class="cms-foot">
            <span
              @click="jumpPhotoPage(photoList16[0].FGameNo, photoList16[0].FType)"
            >
              查看更多 >
            </span>
            <span class="cms-sq" @click="onTsClick($event)">
              <span>收起</span>
              <van-icon name="back-top" />
            </span>
          </div>
        </div>
      </div>
      
        <!-- 图库17 -->
        <div class="cms-box" v-if="photoList17.length">
        <div class="cms-main">
          <div class="cms-head">
            <span>{{ getTitle(curActive) }}</span>
            <span class="title">【 {{ photoList17[0]?.FSubType1 }} 】</span>
          </div>
          <div class="cms-cont">
            <div class="cms-dq">
              <span
                v-for="(item, i) in photoList17"
                :key="i"
                :class="{ active: i == curPhoto }"
                @click="curPhoto = i"
              >
                {{ item.FName }}
              </span>
            </div>
            <ul class="cms-list">
              <li
                class="cms-hide"
                v-for="(item, i) in photoList17"
                :key="i"
                :class="{ active: i == curPhoto }"
              >
                <img :src="item.FImg" alt="" />
              </li>
            </ul>
          </div>
          <div class="cms-foot">
            <span
              @click="jumpPhotoPage(photoList17[0].FGameNo, photoList17[0].FType)"
            >
              查看更多 >
            </span>
            <span class="cms-sq" @click="onTsClick($event)">
              <span>收起</span>
              <van-icon name="back-top" />
            </span>
          </div>
        </div>
      </div>
        <!-- 图库18 -->
       <div class="cms-box" v-if="photoList18.length">
        <div class="cms-main">
          <div class="cms-head">
            <span>{{ getTitle(curActive) }}</span>
            <span class="title">【 {{ photoList18[0]?.FSubType1 }} 】</span>
          </div>
          <div class="cms-cont">
            <div class="cms-dq">
              <span
                v-for="(item, i) in photoList18"
                :key="i"
                :class="{ active: i == curPhoto }"
                @click="curPhoto = i"
              >
                {{ item.FName }}
              </span>
            </div>
            <ul class="cms-list">
              <li
                class="cms-hide"
                v-for="(item, i) in photoList18"
                :key="i"
                :class="{ active: i == curPhoto }"
              >
                <img :src="item.FImg" alt="" />
              </li>
            </ul>
          </div>
          <div class="cms-foot">
            <span
              @click="jumpPhotoPage(photoList18[0].FGameNo, photoList18[0].FType)"
            >
              查看更多 >
            </span>
            <span class="cms-sq" @click="onTsClick($event)">
              <span>收起</span>
              <van-icon name="back-top" />
            </span>
          </div>
        </div>
      </div>
        <!-- 图库19 -->
       <div class="cms-box" v-if="photoList19.length">
        <div class="cms-main">
          <div class="cms-head">
            <span>{{ getTitle(curActive) }}</span>
            <span class="title">【 {{ photoList19[0]?.FSubType1 }} 】</span>
          </div>
          <div class="cms-cont">
            <div class="cms-dq">
              <span
                v-for="(item, i) in photoList19"
                :key="i"
                :class="{ active: i == curPhoto }"
                @click="curPhoto = i"
              >
                {{ item.FName }}
              </span>
            </div>
            <ul class="cms-list">
              <li
                class="cms-hide"
                v-for="(item, i) in photoList19"
                :key="i"
                :class="{ active: i == curPhoto }"
              >
                <img :src="item.FImg" alt="" />
              </li>
            </ul>
          </div>
          <div class="cms-foot">
            <span
              @click="jumpPhotoPage(photoList19[0].FGameNo, photoList19[0].FType)"
            >
              查看更多 >
            </span>
            <span class="cms-sq" @click="onTsClick($event)">
              <span>收起</span>
              <van-icon name="back-top" />
            </span>
          </div>
        </div>
      </div>
        <!-- 图库20 -->
       <div class="cms-box" v-if="photoList20.length">
        <div class="cms-main">
          <div class="cms-head">
            <span>{{ getTitle(curActive) }}</span>
            <span class="title">【 {{ photoList20[0]?.FSubType1 }} 】</span>
          </div>
          <div class="cms-cont">
            <div class="cms-dq">
              <span
                v-for="(item, i) in photoList20"
                :key="i"
                :class="{ active: i == curPhoto }"
                @click="curPhoto = i"
              >
                {{ item.FName }}
              </span>
            </div>
            <ul class="cms-list">
              <li
                class="cms-hide"
                v-for="(item, i) in photoList20"
                :key="i"
                :class="{ active: i == curPhoto }"
              >
                <img :src="item.FImg" alt="" />
              </li>
            </ul>
          </div>
          <div class="cms-foot">
            <span
              @click="jumpPhotoPage(photoList20[0].FGameNo, photoList20[0].FType)"
            >
              查看更多 >
            </span>
            <span class="cms-sq" @click="onTsClick($event)">
              <span>收起</span>
              <van-icon name="back-top" />
            </span>
          </div>
        </div>
      </div>
    <!-- 主页广告3 图库下面位置 -->
    <ul class="urls-wrap">
      <li v-for="item in gg3Data" :key="item.id">
        <a :href="item.Furl ? item.Furl : 'javascript:;'" target="_blank">
          <div class="headPath" style="padding: 3px">
            <img :src="getImgPath(item.Fimg)" alt="" />
          </div>
        </a>
      </li>
    </ul>

    <!-- 高手榜区-->
    <div class="cont2-box" v-if="gsbbsData.length > 0">
      <div class="cms-box">
        <div class="cms-main" v-if="gsbbsData.length">
          <div class="cms-head">
            <span>{{ getTitle(curActive) }}</span>
            <span class="title" style="color: #333333">
              【 {{ getStr('高手榜') }}论坛】
            </span>
          </div>
          <div class="cms-cont">
            <div
              class="bbsCont"
              style="background: #fcece8"
              v-for="item2 in gsbbsData"
              :key="item2.FID"
              @click="jumpDetail(item2.FID)"
            >
              <div class="bbsCont-left">
                <van-tag color="#c26832">高手贴</van-tag>
                <img src="@/assets/images/right.png" alt="" />
                <h4>{{ item2.FName }}</h4>
              </div>
              <div class="bbsCont-right">
                <span>{{ item2.nickName }}</span>
              </div>
            </div>
          </div>
          <div class="cms-foot">
            <span
              @click="jumpForumPage(photoList[0].FGameNo, photoList[0].FType)"
            >
              查看更多 >
            </span>
            <span class="cms-sq" @click="onTsClick($event)">
              <span>收起</span>
              <van-icon name="back-top" />
            </span>
          </div>
        </div>
      </div>
    </div>

    <!-- 新增23模块内容 -->
    <div class="cms-box" v-for="(cms, i) in cmsList" :key="i">
      <!-- 彩种判断开始（不会报错 -->
      <template v-if="cms[0].FGid == curActive">
        <!-- 主页广告4 四肖15码上面 -->
        <template v-if="cms[0]?.FType == 1">
          <ul class="urls-wrap">
            <li v-for="item in gg4Data" :key="item.id">
              <a :href="item.Furl ? item.Furl : 'javascript:;'" target="_blank">
                <div class="headPath" style="padding: 3px">
                  <img :src="getImgPath(item.Fimg)" alt="" />
                </div>
              </a>
            </li>
          </ul>
        </template>
        <!-- 主页广告5 三头中特上面 -->
        <template v-if="cms[0]?.FType == 4">
          <ul class="urls-wrap">
            <li v-for="item in gg5Data" :key="item.id">
              <a :href="item.Furl ? item.Furl : 'javascript:;'" target="_blank">
                <div class="headPath" style="padding: 3px">
                  <img :src="getImgPath(item.Fimg)" alt="" />
                </div>
              </a>
            </li>
          </ul>
        </template>
        <!-- 主页广告6  平特一肖上面 -->
        <template v-if="cms[0]?.FType == 7">
          <ul class="urls-wrap">
            <li v-for="item in gg6Data" :key="item.id">
              <a :href="item.Furl ? item.Furl : 'javascript:;'" target="_blank">
                <div class="headPath" style="padding: 3px">
                  <img :src="getImgPath(item.Fimg)" alt="" />
                </div>
              </a>
            </li>
          </ul>
        </template>
        <!-- 主页广告7 男女肖中特上面 -->
        <template v-if="cms[0]?.FType == 10">
          <ul class="urls-wrap">
            <li v-for="item in gg7Data" :key="item.id">
              <a :href="item.Furl ? item.Furl : 'javascript:;'" target="_blank">
                <div class="headPath" style="padding: 3px">
                  <img :src="getImgPath(item.Fimg)" alt="" />
                </div>
              </a>
            </li>
          </ul>
        </template>
        <!-- 主页广告8 一肖一码上面 -->
        <template v-if="cms[0]?.FType == 13">
          <ul class="urls-wrap">
            <li v-for="item in gg8Data" :key="item.id">
              <a :href="item.Furl ? item.Furl : 'javascript:;'" target="_blank">
                <div class="headPath" style="padding: 3px">
                  <img :src="getImgPath(item.Fimg)" alt="" />
                </div>
              </a>
            </li>
          </ul>
        </template>
        <!-- 主页广告9 春夏秋冬肖上面 -->
        <template v-if="cms[0]?.FType == 16">
          <ul class="urls-wrap">
            <li v-for="item in gg9Data" :key="item.id">
              <a :href="item.Furl ? item.Furl : 'javascript:;'" target="_blank">
                <div class="headPath" style="padding: 3px">
                  <img :src="getImgPath(item.Fimg)" alt="" />
                </div>
              </a>
            </li>
          </ul>
        </template>
        <!-- 主页广告10 大小单双上面 -->
        <template v-if="cms[0]?.FType == 19">
          <ul class="urls-wrap">
            <li v-for="item in gg9Data" :key="item.id">
              <a :href="item.Furl ? item.Furl : 'javascript:;'" target="_blank">
                <div class="headPath" style="padding: 3px">
                  <img :src="getImgPath(item.Fimg)" alt="" />
                </div>
              </a>
            </li>
          </ul>
        </template>
        <!-- 主页广告11 稳杀5码上面 -->
        <template v-if="cms[0]?.FType == 22">
          <ul class="urls-wrap">
            <li v-for="item in gg11Data" :key="item.id">
              <a :href="item.Furl ? item.Furl : 'javascript:;'" target="_blank">
                <div class="headPath" style="padding: 3px">
                  <img :src="getImgPath(item.Fimg)" alt="" />
                </div>
              </a>
            </li>
          </ul>
        </template>
        <div class="cms-main" v-if="cms.length">
          <div
            class="cms-jump"
            :id="'tagblock-' + cms[0]?.FType"
            style="position: relative; top: -50px"
          ></div>
          <div class="cms-head">
            <span>{{ getTitle(curActive) }}</span>
            <span class="title">【 {{ cms[0]?.FTypeName }} 】</span>
            <!-- <span>{{ getTitle(curActive) }}</span> -->
            <!-- <div class="cms-keep" @click="setKeep(cms[0])">
              <van-icon name="star" v-if="keepList.indexOf(cms[0].FID) != -1" />
              <van-icon name="star-o" v-else />
              <span>收藏</span>
            </div> -->
          </div>
          <div class="cms-cont">
            <ul class="cms-list">
              <li v-if="cms[0].FType == 4">
                <div class="cms-rule">
                  <p
                    style="
                      color: red;
                      padding: 0px 2.3rem;
                      font-size: 0.526667rem;
                    "
                  >
                    头数:
                    <a style="color: #0d5eef">0.1.2.3.4.头</a>
                  </p>
                </div>
              </li>
              <li v-if="cms[0].FType == 5">
                <div class="cms-rule">
                  <p
                    style="
                      color: red;
                      padding: 0px 2rem;
                      font-size: 0.526667rem;
                    "
                  >
                    尾数:
                    <a style="color: #0d5eef">1.2.3.4.5.6.7.8.9</a>
                  </p>
                </div>
              </li>
              <li v-if="cms[0].FType == 8">
                <div
                  class="cms-rule"
                  style="padding: 0px 1.2rem; font-size: 0.526667rem"
                >
                  <p>
                    <span style="color: red">家禽:</span>
                    牛、马、羊、鸡、狗、猪
                  </p>
                  <p>
                    <span style="color: red">野兽:</span>
                    鼠、虎、兔、龙、蛇、猴
                  </p>
                </div>
              </li>

              <li v-if="cms[0].FType == 10">
                <div
                  class="cms-rule"
                  style="padding: 0px 0.5rem; font-size: 0.526667rem"
                >
                  <p>
                    <span style="color: red">男肖:</span>
                    鼠、牛、虎、龙、马、猴、狗
                  </p>
                  <p>
                    <span style="color: red">女肖:</span>
                    兔、蛇、羊、鸡、猪（五宫肖）
                  </p>
                </div>
              </li>
              <li v-if="cms[0].FType == 11">
                <div
                  class="cms-rule"
                  style="padding: 8px 1.2rem; font-size: 0.526667rem"
                >
                  <p>
                    <span style="color: red">合数小：</span>
                    01-06
                    <span style="color: red">合数大：</span>
                    07-13
                  </p>
                </div>
              </li>
              <li v-if="cms[0].FType == 15">
                <div class="cms-rule">
                  <p>
                    <span style="color: red">金行（10支）：</span>
                    02.03.10.11.24.25.32.33.40.41
                  </p>
                  <p>
                    <span style="color: red">木行（10支）：</span>
                    06.07.14.15.22.23.36.37.44.45
                  </p>
                  <p>
                    <span style="color: red">水行（8支）：</span>
                    12.13.20.21.28.29.42.43
                  </p>
                  <p>
                    <span style="color: red">火行（11支）：</span>
                    01.08.09.16.17.30.31.38.39.46.47
                  </p>
                  <p>
                    <span style="color: red">土行（10支）：</span>
                    04.05.18.19.26.27.34.35.48.49
                  </p>
                </div>
              </li>
              <li v-if="cms[0].FType == 14">
                <div
                  class="cms-rule"
                  style="padding: 0px 1rem; font-size: 0.526667rem"
                >
                  <p>
                    <span style="color: red">天肖：</span>
                    兔、马、猴、猪、牛、龙
                  </p>
                  <p>
                    <span style="color: red">地肖：</span>
                    蛇、羊、鸡、狗、鼠、虎
                  </p>
                </div>
              </li>
              <li v-if="cms[0].FType == 16">
                <div
                  class="cms-rule"
                  style="padding: 0px 1.7rem; font-size: 0.526667rem"
                >
                  <p style="color: #1989fa">
                    【春】虎兔龙
                    <a style="color: #bc0aef">【夏】蛇马羊</a>
                  </p>
                  <p style="color: #16b777">
                    【秋】猴狗鸡
                    <a style="color: #f13131">【冬】猪鼠牛</a>
                  </p>
                </div>
              </li>
              <li v-if="cms[0].FType == 21">
                <div
                  class="cms-rule"
                  style="padding: 0px 2.4rem; font-size: 0.526667rem"
                >
                  <p style="color: #1989fa">吃肉肖【虎蛇龙狗】</p>
                  <p style="color: #16b777">吃菜肖【猪鼠鸡猴】</p>
                  <p style="color: #bc0aef">吃草肖【牛羊马兔】</p>
                </div>
              </li>
              <!--  -->
              <!--  -->
              <li v-for="item in cms" :key="item.FID">
                <template v-if="item.IsCustom">
                  <div class="qh">{{ item.FName }}</div>
                  <div class="kj" v-html="item.FContent"></div>
                  <div class="js" v-if="item.FOpenCode">
                    <span>
                      <span>
                        {{ item.Result }}
                      </span>
                      <span
                        class="cms-green"
                        v-if="item.IsRight==null" 
                      >
                        
                      </span>
                      <span
                        class="cms-green"
                        v-else-if="item.IsRight" 
                      >
                        准
                      </span>
                      <span v-else class="cms-green">错</span>
                    </span>
                  </div>
                </template>
                <!-- FRule	规则  0要参与计算  1不用 -->
                <template v-if="!item.IsCustom&&!item.FRule">
                  <!-- <div class="qh">{{ item.FPeriods.substring(4) }}期</div> -->
                  <div class="qh">{{ item.FName }}</div>
                  <!-- <div class="kj">
              <span>{{ countWordsWithoutHTML(item.FContent) }}</span>
            </div> -->
                  <div class="kj" v-html="item.FContent"></div>
                  <div class="js" v-if="item.FOpenCode">
                    <!--  -->
                    <span v-if="item.FType == 1 || item.FType == 2">
                      <span>
                        开:{{
                          czTool.lhcSx(
                            item.FOpenCode.split(',').slice(-1).toString()
                          )
                        }}
                        {{ item.FOpenCode.split(',').slice(-1).toString() }}
                      </span>
                      <span
                        class="cms-green"
                        v-if="
                          countWordsWithoutHTML(item.FContent).includes(
                            item.FOpenCode.split(',').slice(-1).toString()
                          )
                        "
                      >
                        准
                      </span>
                      <span v-else class="cms-green">错</span>
                    </span>
                    <!--  -->
                    <span v-else-if="item.FType == 3">
                      <span>
                        开:{{
                          czTool.lhcSx(
                            item.FOpenCode.split(',').slice(-1).toString()
                          )
                        }}
                        {{ item.FOpenCode.split(',').slice(-1).toString() }}
                      </span>

                      <span
                        class="cms-green"
                        v-if="
                          countWordsWithoutHTML(item.FContent).includes(
                            czTool.czds2(
                              item.FOpenCode.split(',').slice(-1).toString()
                            )
                          )
                        "
                      >
                        准
                      </span>
                      <span v-else class="cms-green">错</span>
                    </span>
                    <!--  -->
                    <span v-else-if="item.FType == 4">
                      <span>
                        开:{{
                          czTool.lhcSx(
                            item.FOpenCode.split(',').slice(-1).toString()
                          )
                        }}
                        {{ item.FOpenCode.split(',').slice(-1).toString() }}
                      </span>
                      <span
                        class="cms-green"
                        v-if="
                          countWordsWithoutHTML(item.FContent).includes(
                            item.FOpenCode.split(',')
                              .slice(-1)
                              .toString()
                              .slice(0, 1)
                          )
                        "
                      >
                        准
                      </span>
                      <span v-else class="cms-green">错</span>
                    </span>
                    <!--  -->
                    <span v-else-if="item.FType == 5">
                      <span>
                        开:{{
                          czTool.lhcSx(
                            item.FOpenCode.split(',').slice(-1).toString()
                          )
                        }}
                        {{ item.FOpenCode.split(',').slice(-1).toString() }}
                      </span>
                      <span
                        class="cms-green"
                        v-if="
                          countWordsWithoutHTML(item.FContent).includes(
                            item.FOpenCode.split(',')
                              .slice(-1)
                              .toString()
                              .slice(-1)
                          )
                        "
                      >
                        准
                      </span>
                      <span v-else class="cms-green">错</span>
                    </span>
                    <!--  -->
                    <span v-else-if="item.FType == 6">
                      <span>
                        开:{{
                          czTool.lhcSx(
                            item.FOpenCode.split(',').slice(-1).toString()
                          )
                        }}
                        <!-- <span
                    class="cms-green"
                    :style="{
                      color:
                        czTool.lhchll(
                          item.FOpenCode.split(',').slice(-1).toString()
                        ) == '绿'
                          ? 'green'
                          : 'blue',
                    }"
                  >
                    {{
                      czTool.lhchll(
                        item.FOpenCode.split(',').slice(-1).toString()
                      )
                    }}波
                  </span> -->
                        {{ item.FOpenCode.split(',').slice(-1).toString() }}
                      </span>
                      <span
                        class="cms-green"
                        v-if="
                          countWordsWithoutHTML(item.FContent).includes(
                            czTool.lhchll(
                              item.FOpenCode.split(',').slice(-1).toString()
                            )
                          )
                        "
                      >
                        准
                      </span>
                      <span v-else class="cms-green">错</span>
                    </span>
                    <!--  -->
                    <span
                      v-if="
                        item.FType == 9 ||
                        item.FType == 12 ||
                        item.FType == 13 ||
                        item.FType == 17 ||
                        item.FType == 20
                      "
                    >
                      <span>
                        开:{{
                          czTool.lhcSx(
                            item.FOpenCode.split(',').slice(-1).toString()
                          )
                        }}
                        {{ item.FOpenCode.split(',').slice(-1).toString() }}
                      </span>
                      <span
                        class="cms-green"
                        v-if="
                          countWordsWithoutHTML(item.FContent).includes(
                            czTool.lhcSx(
                              item.FOpenCode.split(',').slice(-1).toString()
                            )
                          )
                        "
                      >
                        准
                      </span>
                      <span v-else class="cms-green">错</span>
                    </span>
                    <!--  -->
                    <span v-else-if="item.FType == 8">
                      <span>
                        开
                        {{
                          czTool.lhcSx(
                            item.FOpenCode.split(',').slice(-1).toString()
                          )
                        }}
                        {{ item.FOpenCode.split(',').slice(-1).toString() }}
                      </span>
                      <span
                        class="cms-green"
                        v-if="
                          countWordsWithoutHTML(item.FContent).includes(
                            czTool.lhcTmYJ(item.FOpenCode)
                          )
                        "
                      >
                        准
                      </span>
                      <span v-else class="cms-green">错</span>
                    </span>
                    <!--  -->
                    <span v-else-if="item.FType == 10">
                      <span>
                        开:{{
                          czTool.lhcSx(
                            item.FOpenCode.split(',').slice(-1).toString()
                          )
                        }}
                        {{ item.FOpenCode.split(',').slice(-1).toString() }}
                      </span>
                      <span
                        class="cms-green"
                        v-if="
                          countWordsWithoutHTML(item.FContent).includes(
                            czTool.lhcnnx(
                              item.FOpenCode.split(',').slice(-1).toString()
                            )
                          )
                        "
                      >
                        准
                      </span>
                      <span v-else class="cms-green">错</span>
                    </span>
                    <!--  -->
                    <span v-else-if="item.FType == 11">
                      <span>
                        开:{{
                          czTool.lhcSx(
                            item.FOpenCode.split(',').slice(-1).toString()
                          )
                        }}
                        {{ item.FOpenCode.split(',').slice(-1).toString() }}
                      </span>
                      <span
                        class="cms-green"
                        v-if="
                          countWordsWithoutHTML(item.FContent).includes(
                            czTool.lhchdhx(
                              item.FOpenCode.split(',').slice(-1).toString()
                            )
                          )
                        "
                      >
                        准
                      </span>
                      <span v-else class="cms-green">错</span>
                    </span>
                    <!--  -->
                    <span v-else-if="item.FType == 14">
                      <span>
                        开:{{
                          czTool.lhcSx(
                            item.FOpenCode.split(',').slice(-1).toString()
                          )
                        }}
                        {{ item.FOpenCode.split(',').slice(-1).toString() }}
                      </span>
                      <span
                        class="cms-green"
                        v-if="
                          countWordsWithoutHTML(item.FContent).includes(
                            czTool.lhctdx(
                              item.FOpenCode.split(',').slice(-1).toString()
                            )
                          )
                        "
                      >
                        准
                      </span>
                      <span v-else class="cms-green">错</span>
                    </span>
                    <!--  -->
                    <span v-else-if="item.FType == 15">
                      <span>
                        开:{{
                          czTool.lhcSx(
                            item.FOpenCode.split(',').slice(-1).toString()
                          )
                        }}
                        {{ item.FOpenCode.split(',').slice(-1).toString() }}
                      </span>
                      <span
                        class="cms-green"
                        v-if="
                          countWordsWithoutHTML(item.FContent).includes(
                            czTool.lhchwx(
                              item.FOpenCode.split(',').slice(-1).toString()
                            )
                          )
                        "
                      >
                        准
                      </span>
                      <span v-else class="cms-green">错</span>
                    </span>
                    <!--  -->
                    <span v-else-if="item.FType == 16">
                      <span>
                        开:{{
                          czTool.lhcSx(
                            item.FOpenCode.split(',').slice(-1).toString()
                          )
                        }}
                        {{ item.FOpenCode.split(',').slice(-1).toString() }}
                      </span>
                      <span
                        class="cms-green"
                        v-if="
                          countWordsWithoutHTML(item.FContent).includes(
                            czTool.lhccxqd(
                              item.FOpenCode.split(',').slice(-1).toString()
                            )
                          )
                        "
                      >
                        准
                      </span>
                      <span v-else class="cms-green">错</span>
                    </span>
                    <!-- 一句爆平特 18 有点复杂略 -->
                    <span v-else-if="item.FType == 7 || item.FType == 18">
                      <span>
                        开:
                        {{
                          yjbptJs(
                            item.FOpenCode,
                            countWordsWithoutHTML(item.FContent)
                          ).sx
                        }}
                        {{
                          yjbptJs(
                            item.FOpenCode,
                            countWordsWithoutHTML(item.FContent)
                          ).num
                        }}
                      </span>
                      <span class="cms-green">
                        {{
                          yjbptJs(
                            item.FOpenCode,
                            countWordsWithoutHTML(item.FContent)
                          ).desc
                        }}
                      </span>
                    </span>
                    <!--  -->
                    <span v-else-if="item.FType == 19">
                      <span>
                        开:{{
                          czTool.lhcSx(
                            item.FOpenCode.split(',').slice(-1).toString()
                          )
                        }}
                        {{ item.FOpenCode.split(',').slice(-1).toString() }}
                      </span>
                      <span
                        class="cms-green"
                        v-if="
                          countWordsWithoutHTML(item.FContent).includes(
                            czTool.lhcddxd(
                              item.FOpenCode.split(',').slice(-1).toString()
                            )
                          )
                        "
                      >
                        准
                      </span>
                      <span v-else class="cms-green">错</span>
                    </span>
                    <!--  -->
                    <span v-else-if="item.FType == 21">
                      <span>
                        开:{{
                          czTool.lhcSx(
                            item.FOpenCode.split(',').slice(-1).toString()
                          )
                        }}
                        {{ item.FOpenCode.split(',').slice(-1).toString() }}
                      </span>
                      <span
                        class="cms-green"
                        v-if="
                          countWordsWithoutHTML(item.FContent).includes(
                            czTool.lhcrcc(
                              item.FOpenCode.split(',').slice(-1).toString()
                            )
                          )
                        "
                      >
                        准
                      </span>
                      <span v-else class="cms-green">错</span>
                    </span>
                    <!--  -->
                    <span v-else-if="item.FType == 22">
                      <span>
                        开:{{
                          czTool.lhcSx(
                            item.FOpenCode.split(',').slice(-1).toString()
                          )
                        }}
                        {{ item.FOpenCode.split(',').slice(-1).toString() }}
                      </span>
                      <span
                        class="cms-green"
                        v-if="
                          !countWordsWithoutHTML(item.FContent).includes(
                            item.FOpenCode.split(',').slice(-1).toString()
                          )
                        "
                      >
                        准
                      </span>
                      <span v-else class="cms-green">错</span>
                    </span>
                    <span v-else-if="item.FType == 23">
                      <span>开:</span>
                      <span class="cms-green">
                        {{
                          yjbptJs2(
                            item.FOpenCode,
                            countWordsWithoutHTML(item.FContent)
                          ).len
                        }}个
                      </span>
                    </span>
                  </div>
                  <div class="js" v-else>
                    <span v-if="item.FType == 23">
                      开:?
                      <span class="cms-green">个</span>
                    </span>
                    <span v-else>
                      开:? 00
                      <span class="cms-green">准</span>
                    </span>
                  </div>
                </template>
                <template v-else-if="!item.IsCustom">
                  <div class="kj" v-html="item.FContent"></div>
                </template>
              </li>
            </ul>
          </div>
          <div class="cms-foot">
            <span @click="jumpCmsDetail(cms[0]?.FType)">查看往期记录 ></span>
            <span class="cms-sq" @click="onTsClick($event)">
              <span>收起</span>
              <van-icon name="back-top" />
            </span>
          </div>
        </div>
      </template>
      <!-- 彩种判断结束 -->
    </div>
    <!-- 主页广告12 2024生肖上面位置 -->
    <ul class="urls-wrap">
      <li v-for="item in gg12Data" :key="item.id">
        <a :href="item.Furl ? item.Furl : 'javascript:;'" target="_blank">
          <div class="headPath" style="padding: 3px">
            <img :src="getImgPath(item.Fimg)" alt="" />
          </div>
        </a>
      </li>
    </ul>

    <!-- <Gallery
      v-if="curActive && curType"
      :uGameNo="curActive"
      :uType="curType"
    /> -->

    <!-- 主页广告13 2024生肖上面位置 -->
    <ul class="urls-wrap">
      <li v-for="item in gg13Data" :key="item.id">
        <a :href="item.Furl ? item.Furl : 'javascript:;'" target="_blank">
          <div class="headPath" style="padding: 3px">
            <img :src="getImgPath(item.Fimg)" alt="" />
          </div>
        </a>
      </li>
    </ul>

    <ol class="home-adRent" v-if="adData.length">
      <li v-for="item in adData" :key="item.id">
        <img src="@/assets/images/home/gg1-icon.png" alt="" />
        <a
          :href="item.Furl ? item.Furl : 'javascript:;'"
          :target="item.Furl ? '_blank' : '_self'"
        >
          <img :src="getImgPath(item.Fimg)" alt="" />
        </a>
      </li>
    </ol>

    <!-- <div class="home-lottery whiteList">
      <p
        v-for="(item, i) in lotteryData"
        :key="i"
        :class="{ active: curActive == item.gameNo }"
        @click="onTab(item.gameNo)"
      >
        {{ item.gameName }}
      </p>
    </div> -->

    <!-- <van-popup v-model="adshow1">
      <div class="home-ad-overlay">
        <div class="home-ad-txt">
          <van-swipe ref="swipeRef" class="home-ad-swipe" indicator-color="white" :loop="false" @change="onChange">
            <van-swipe-item v-for="(item, i) in tanchuangData" :key="i">
              <div class="home-ad-txts">
                <div v-html="item.conTents"></div>
              </div>
            </van-swipe-item>
            <template #indicator>
              <div class="custom-indicator">
                <span @click="onCut(false)">上一个</span>
                <b>{{ current + 1 }}/{{ tanchuangData.length }}</b>
                <span @click="onCut(true)">下一个</span>
              </div>
            </template>
          </van-swipe>
          <div class="close" @click="closeAd1">
            <van-icon name="close" color="#ffffff" size="24px" />
          </div>
        </div>
      </div>
    </van-popup> -->

    <Notice />
    <!-- //弹窗广告-->
    <TouchIcon v-if="sysInfo.Ftouzhu" />
    <!-- //主页投注按纽 -->
    <van-popup v-model="adshow2">内容</van-popup>

    <!-- 返回顶部 -->
    <BackTop />

    <!-- 2024年属性知识 -->
    <div class="cms-box">
      <div class="cms-main">
        <div class="cms-head">
          <span class="title">2024年属性知识</span>
        </div>
        <div class="sx grey-line">
          <table width="100%" border="0" cellspacing="0" cellpadding="0">
            <tbody>
              <tr><th>2024龙年（十二生肖号码对照）</th></tr>
              <tr>
                <td>
                  <ul class="sx1 clearfix">
                    <li>
                      <dl>
                        <dt>
                          龍
                          <img src="@/assets/images/shengxiao/tu.gif" />
                          [冲 狗]
                        </dt>
                        <dd>
                          <span>01</span>
                          <span>13</span>
                          <span class="blue">25</span>
                          <span class="blue">37</span>
                          <span class="green">49</span>
                        </dd>
                      </dl>
                    </li>
                    <li>
                      <dl>
                        <dt>
                          兔
                          <img src="@/assets/images/shengxiao/hu.gif" />
                          [冲 雞]
                        </dt>
                        <dd>
                          <span>02</span>
                          <span class="blue">14</span>
                          <span class="blue">26</span>
                          <span class="green">38</span>
                        </dd>
                      </dl>
                    </li>
                    <li>
                      <dl>
                        <dt>
                          虎
                          <img src="@/assets/images/shengxiao/niu.gif" />
                          [冲 猴]
                        </dt>
                        <dd>
                          <span class="blue">03</span>
                          <span class="blue">15</span>
                          <span class="green">27</span>
                          <span class="green">39</span>
                        </dd>
                      </dl>
                    </li>
                    <li>
                      <dl>
                        <dt>
                          牛
                          <img src="@/assets/images/shengxiao/shu.gif" />
                          [冲 羊]
                        </dt>
                        <dd>
                          <span class="blue">04</span>
                          <span class="green">16</span>
                          <span class="green">28</span>
                          <span>40</span>
                        </dd>
                      </dl>
                    </li>
                    <li>
                      <dl>
                        <dt>
                          鼠
                          <img src="@/assets/images/shengxiao/zhu.gif" />
                          [冲 馬]
                        </dt>
                        <dd>
                          <span class="green">05</span>
                          <span class="green">17</span>
                          <span>29</span>
                          <span class="blue">41</span>
                        </dd>
                      </dl>
                    </li>
                    <li>
                      <dl>
                        <dt>
                          豬
                          <img src="@/assets/images/shengxiao/gou.gif" />
                          [冲 蛇]
                        </dt>
                        <dd>
                          <span class="green">06</span>
                          <span>18</span>
                          <span>30</span>
                          <span class="blue">42</span>
                        </dd>
                      </dl>
                    </li>
                    <li>
                      <dl>
                        <dt>
                          狗
                          <img src="@/assets/images/shengxiao/ji.gif" />
                          [冲 龍]
                        </dt>
                        <dd>
                          <span>07</span>
                          <span>19</span>
                          <span class="blue">31</span>
                          <span class="green">43</span>
                        </dd>
                      </dl>
                    </li>
                    <li>
                      <dl>
                        <dt>
                          雞
                          <img src="@/assets/images/shengxiao/hou.gif" />
                          [冲 兔]
                        </dt>
                        <dd>
                          <span>08</span>
                          <span class="blue">20</span>
                          <span class="green">32</span>
                          <span class="green">44</span>
                        </dd>
                      </dl>
                    </li>
                    <li>
                      <dl>
                        <dt>
                          猴
                          <img src="@/assets/images/shengxiao/yang.gif" />
                          [冲 虎]
                        </dt>
                        <dd>
                          <span class="blue">09</span>
                          <span class="green">21</span>
                          <span class="green">33</span>
                          <span>45</span>
                        </dd>
                      </dl>
                    </li>
                    <li>
                      <dl>
                        <dt>
                          羊
                          <img src="@/assets/images/shengxiao/ma.gif" />
                          [冲 牛]
                        </dt>
                        <dd>
                          <span class="blue">10</span>
                          <span class="green">22</span>
                          <span>34</span>
                          <span>46</span>
                        </dd>
                      </dl>
                    </li>
                    <li>
                      <dl>
                        <dt>
                          馬
                          <img src="@/assets/images/shengxiao/she.gif" />
                          [冲 鼠]
                        </dt>
                        <dd>
                          <span class="green">11</span>
                          <span>23</span>
                          <span>35</span>
                          <span class="blue">47</span>
                        </dd>
                      </dl>
                    </li>
                    <li>
                      <dl>
                        <dt>
                          蛇
                          <img src="@/assets/images/shengxiao/long.gif" />
                          [冲 豬]
                        </dt>
                        <dd>
                          <span>12</span>
                          <span>24</span>
                          <span class="blue">36</span>
                          <span class="blue">48</span>
                        </dd>
                      </dl>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr></tr>
            </tbody>
          </table>
          <table width="100%" border="0" cellspacing="0" cellpadding="0">
            <colgroup>
              <col width="50" />
              <col width="" />
            </colgroup>
            <tbody>
              <tr><th colspan="2">五行对照</th></tr>
              <!-- <tr>
                <td><font color="#ffcc00">金</font></td>
                <td class="a-left">
                  <span>01</span>
                  <span>02</span>
                  <span class="blue">09</span>
                  <span style="background-color: rgb(51, 102, 255)">10</span>
                  <span>23</span>
                  <span>24</span>
                  <span style="background-color: rgb(51, 102, 255)">31</span>
                  <span style="background-color: rgb(0, 128, 0)">32</span>
                  <span style="background-color: rgb(0, 128, 0)">39</span>
                  <span style="background-color: rgb(255, 0, 0)">40</span>
                </td>
              </tr>
              <tr>
                <td><font color="#33cc33">木</font></td>
                <td class="a-left">
                  <span style="background-color: rgb(0, 128, 0)">05</span>
                  <span class="green">06</span>
                  <span>13</span>
                  <span class="blue">14</span>
                  <span style="background-color: rgb(0, 128, 0)">21</span>
                  <span style="background-color: rgb(0, 128, 0)">22</span>
                  <span>35</span>
                  <span class="blue">36</span>
                  <span style="background-color: rgb(0, 128, 0)">43</span>
                  <span style="background-color: rgb(0, 128, 0)">44</span>
                </td>
              </tr>
              <tr>
                <td><font color="#3399ff">水</font></td>
                <td class="a-left">
                  <span style="background-color: rgb(0, 128, 0)">11</span>
                  <span style="background-color: rgb(255, 0, 0)">12</span>
                  <span style="background-color: rgb(255, 0, 0)">19</span>
                  <span class="blue">20</span>
                  <span style="background-color: rgb(0, 128, 0)">27</span>
                  <span class="green">28</span>
                  <span style="background-color: rgb(51, 102, 255)">41</span>
                  <span class="blue">42</span>
                  <span style="background-color: rgb(0, 128, 0)">49</span>
                </td>
              </tr>
              <tr>
                <td><font color="#ff6600">火</font></td>
                <td class="a-left">
                  <span style="background-color: rgb(255, 0, 0)">07</span>
                  <span>08</span>
                  <span style="background-color: rgb(51, 102, 255)">15</span>
                  <span class="green">16</span>
                  <span>29</span>
                  <span>30</span>
                  <span style="background-color: rgb(51, 102, 255)">37</span>
                  <span class="green">38</span>
                  <span style="background-color: rgb(255, 0, 0)">45</span>
                  <span>46</span>
                </td>
              </tr>
              <tr>
                <td><font color="#cc9900">土</font></td>
                <td class="a-left">
                  <span class="blue">03</span>
                  <span class="blue">04</span>
                  <span style="background-color: rgb(0, 128, 0)">17</span>
                  <span style="background-color: rgb(255, 0, 0)">18</span>
                  <span class="blue">25</span>
                  <span class="blue">26</span>
                  <span style="background-color: rgb(0, 128, 0)">33</span>
                  <span style="background-color: rgb(255, 0, 0)">34</span>
                  <span style="background-color: rgb(51, 102, 255)">47</span>
                  <span class="blue">48</span>
                </td>
              </tr> -->
              <tr>
                <td><font color="#ffcc00">金</font></td>
                <td class="a-left">
                  <span>02</span>
                  <span class="blue">03</span>
                  <span class="blue">10</span>
                  <span style="background-color: rgb(0, 128, 0)">11</span>
                  <span>24</span>
                  <span class="blue">25</span>
                  <span style="background-color: rgb(0, 128, 0)">32</span>
                  <span style="background-color: rgb(0, 128, 0)">33</span>
                  <span>40</span>
                  <span class="blue">41</span>
                </td>
              </tr>
              <tr>
                <td><font color="#33cc33">木</font></td>
                <td class="a-left">
                  <span style="background-color: rgb(0, 128, 0)">06</span>
                  <span>07</span>
                  <span class="blue">14</span>
                  <span class="blue">15</span>
                  <span style="background-color: rgb(0, 128, 0)">22</span>
                  <span>23</span>
                  <span class="blue">36</span>
                  <span class="blue">37</span>
                  <span style="background-color: rgb(0, 128, 0)">44</span>
                  <span>45</span>
                </td>
              </tr>
              <tr>
                <td><font color="#3399ff">水</font></td>
                <td class="a-left">
                  <span>12</span>
                  <span>13</span>
                  <span class="blue">20</span>
                  <span style="background-color: rgb(0, 128, 0)">21</span>
                  <span style="background-color: rgb(0, 128, 0)">28</span>
                  <span>29</span>
                  <span class="blue">42</span>
                  <span style="background-color: rgb(0, 128, 0)">43</span>
                </td>
              </tr>
              <tr>
                <td><font color="#ff6600">火</font></td>
                <td class="a-left">
                  <span>01</span>
                  <span>08</span>
                  <span class="blue">09</span>
                  <span class="green">16</span>
                  <span class="green">17</span>
                  <span>30</span>
                  <span class="blue">31</span>
                  <span class="green">38</span>
                  <span class="green">39</span>
                  <span>46</span>
                  <span class="blue">47</span>
                </td>
              </tr>
              <tr>
                <td><font color="#cc9900">土</font></td>
                <td class="a-left">
                  <span class="blue">04</span>
                  <span class="green">05</span>
                  <span>18</span>
                  <span>19</span>
                  <span class="blue">26</span>
                  <span class="green">27</span>
                  <span>34</span>
                  <span>35</span>
                  <span class="blue">48</span>
                  <span class="green">49</span>
                </td>
              </tr>
            </tbody>
          </table>
          <table width="100%" border="0" cellspacing="0" cellpadding="0">
            <colgroup>
              <col width="50" />
              <col width="" />
            </colgroup>
            <tbody>
              <tr><th colspan="2">波色</th></tr>
              <tr>
                <td><font color="#ff0000">红波</font></td>
                <td class="a-left">
                  <span>01</span>
                  <span>02</span>
                  <span>07</span>
                  <span>08</span>
                  <span>12</span>
                  <span>13</span>
                  <span>18</span>
                  <span>19</span>
                  <span>23</span>
                  <span>24</span>
                  <span>29</span>
                  <span>30</span>
                  <span>34</span>
                  <span>35</span>
                  <span>40</span>
                  <span>45</span>
                  <span>46</span>
                </td>
              </tr>
              <tr>
                <td><font color="#3366ff">蓝波</font></td>
                <td class="a-left">
                  <span class="blue">03</span>
                  <span class="blue">04</span>
                  <span class="blue">09</span>
                  <span class="blue">10</span>
                  <span class="blue">14</span>
                  <span class="blue">15</span>
                  <span class="blue">20</span>
                  <span class="blue">25</span>
                  <span class="blue">26</span>
                  <span class="blue">31</span>
                  <span class="blue">36</span>
                  <span class="blue">37</span>
                  <span class="blue">41</span>
                  <span class="blue">42</span>
                  <span class="blue">47</span>
                  <span class="blue">48</span>
                </td>
              </tr>
              <tr>
                <td><font color="#009933">绿波</font></td>
                <td class="a-left">
                  <span class="green">05</span>
                  <span class="green">06</span>
                  <span class="green">11</span>
                  <span class="green">16</span>
                  <span class="green">17</span>
                  <span class="green">21</span>
                  <span class="green">22</span>
                  <span class="green">27</span>
                  <span class="green">28</span>
                  <span class="green">32</span>
                  <span class="green">33</span>
                  <span class="green">38</span>
                  <span class="green">39</span>
                  <span class="green">43</span>
                  <span class="green">44</span>
                  <span class="green">49</span>
                </td>
              </tr>
            </tbody>
          </table>
          <table width="100%" border="0" cellspacing="0" cellpadding="0">
            <colgroup>
              <col width="50" />
              <col width="" />
            </colgroup>
            <tbody>
              <tr><th colspan="2">合数单双</th></tr>
              <tr>
                <td><font color="#3366ff">合数单</font></td>
                <td class="a-left">
                  <span>01</span>
                  <span class="blue">03</span>
                  <span class="green">05</span>
                  <span>07</span>
                  <span class="blue">09</span>
                  <span class="blue">10</span>
                  <span>12</span>
                  <span class="blue">14</span>
                  <span class="green">16</span>
                  <span>18</span>
                  <span class="green">21</span>
                  <span>23</span>
                  <span class="blue">25</span>
                  <span class="green">27</span>
                  <span>29</span>
                  <span>30</span>
                  <span class="green">32</span>
                  <span>34</span>
                  <span class="blue">36</span>
                  <span class="green">38</span>
                  <span class="blue">41</span>
                  <span class="green">43</span>
                  <span>45</span>
                  <span class="blue">47</span>
                  <span class="green">49</span>
                </td>
              </tr>
              <tr>
                <td><font color="#3366ff">合数双</font></td>
                <td class="a-left">
                  <span>02</span>
                  <span class="blue">04</span>
                  <span class="green">06</span>
                  <span>08</span>
                  <span class="green">11</span>
                  <span>13</span>
                  <span class="blue">15</span>
                  <span class="green">17</span>
                  <span>19</span>
                  <span class="blue">20</span>
                  <span class="green">22</span>
                  <span>24</span>
                  <span class="blue">26</span>
                  <span class="green">28</span>
                  <span class="blue">31</span>
                  <span class="green">33</span>
                  <span>35</span>
                  <span class="blue">37</span>
                  <span class="green">39</span>
                  <span>40</span>
                  <span class="blue">42</span>
                  <span class="green">44</span>
                  <span>46</span>
                  <span class="blue">48</span>
                </td>
              </tr>
            </tbody>
          </table>
          <table width="100%" border="0" cellspacing="0" cellpadding="0">
            <tbody>
              <tr><th>生肖属性</th></tr>
              <tr>
                <td>
                  <font color="#3366ff">家禽：</font>
                  牛、马、羊、鸡、狗、猪
                </td>
              </tr>
              <tr>
                <td>
                  <font color="#3366ff">野兽：</font>
                  鼠、虎、兔、龙、蛇、猴
                </td>
              </tr>
              <tr>
                <td>
                  <font color="#3366ff">吉美：</font>
                  兔、龙、蛇、马、羊、鸡
                </td>
              </tr>
              <tr>
                <td>
                  <font color="#3366ff">凶丑：</font>
                  鼠、牛、虎、猴、狗、猪
                </td>
              </tr>
              <tr>
                <td>
                  <font color="#3366ff">阴性：</font>
                  鼠、龙、蛇、马、狗、猪
                </td>
              </tr>
              <tr>
                <td>
                  <font color="#3366ff">阳性：</font>
                  牛、虎、兔、羊、猴、鸡
                </td>
              </tr>
              <tr>
                <td>
                  <font color="#3366ff">单笔：</font>
                  鼠、龙、马、蛇、鸡、猪
                </td>
              </tr>
              <tr>
                <td>
                  <font color="#3366ff">双笔：</font>
                  虎、猴、狗、兔、羊、牛
                </td>
              </tr>
              <tr>
                <td>
                  <font color="#3366ff">天肖：</font>
                  兔、马、猴、猪、牛、龙
                </td>
              </tr>
              <tr>
                <td>
                  <font color="#3366ff">地肖：</font>
                  蛇、羊、鸡、狗、鼠、虎
                </td>
              </tr>
              <tr>
                <td>
                  <font color="#3366ff">白边：</font>
                  鼠、牛、虎、鸡、狗、猪
                </td>
              </tr>
              <tr>
                <td>
                  <font color="#3366ff">黑中：</font>
                  兔、龙、蛇、马、羊、猴
                </td>
              </tr>
              <tr>
                <td>
                  <font color="#3366ff">女肖：</font>
                  兔、蛇、羊、鸡、猪（五宫肖）
                </td>
              </tr>
              <tr>
                <td>
                  <font color="#3366ff">男肖：</font>
                  鼠、牛、虎、龙、马、猴、狗
                </td>
              </tr>
              <tr>
                <td>
                  <font color="#3366ff">三合：</font>
                  鼠龙猴、牛蛇鸡、虎马狗、兔羊猪
                </td>
              </tr>
              <tr>
                <td>
                  <font color="#3366ff">六合：</font>
                  鼠牛、龙鸡、虎猪、蛇猴、兔狗、马羊
                </td>
              </tr>
              <tr>
                <td>
                  <font color="#3366ff">琴:</font>
                  兔蛇鸡　
                  <font color="#3366ff">棋:</font>
                  鼠牛狗　
                  <font color="#3366ff">书:</font>
                  虎龙马　
                  <font color="#3366ff">画:</font>
                  羊猴猪
                </td>
              </tr>
              <tr>
                <td>
                  <font color="#3366ff">五福肖</font>
                  ：鼠、虎、兔、蛇、猴[龙]
                </td>
              </tr>
              <tr>
                <td>
                  <font color="#3366ff">红肖：</font>
                  马、兔、鼠、鸡
                </td>
              </tr>
              <tr>
                <td>
                  <font color="#3366ff">蓝肖：</font>
                  蛇、虎、猪、猴
                </td>
              </tr>
              <tr>
                <td>
                  <font color="#3366ff">绿肖：</font>
                  羊、龙、牛、狗
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="cms-foot"></div>
      </div>
    </div>
    <div style="height: 50px"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  getBanner,
  getAd,
  getGG1,
  getGG2,
  getGG3,
  getGG4,
  getGG5,
  getGG6,
  getGG7,
  getGG8,
  getGG9,
  getGG10,
  getGG11,
  getGG12,
  getGG13,
} from '@/api/user'
import {
  getForumType,
  getGsBBS,
  UpdateForumFavorites,
  getMyFavorites,
  getDataPhoto2
} from '@/api/forum'
import { getNotice } from '@/api/sysConfig'
import {
  getXgNextTime,
  getLottery,
  getCMSListByPage,
  getCMSTagList,
  getCMSIndex,
  getDataPhotoCMS,
  getIndexGames
} from '@/api/lottery'
import { merge, orderBy } from 'lodash'
import Cookies from 'js-cookie'
import Draw from '@/components/Draw'
import Gallery from '@/components/Gallery'
import Notice from '@/components/Notice' //弹窗广告
import TouchIcon from '@/components/TouchIcon' //微信
import BackTop from '@/components/BackTop' //微信
import czTool from '@/utils/cztool'
import { Tag, Toast } from 'vant'
import logo from '@/assets/images/home/600kjaa.gif'
import logo0600 from '@/assets/images/home/600kjaa.0060.one.gif'
import logo00862zxcom from '@/assets/images/home/600kjaa.00862zxcom.gif'
export default {
  name: 'Home',
  components: { Draw, Gallery, Notice, TouchIcon, BackTop, vanTag: Tag },
  data() {
    return {
      lotteryData: [],
      current: 0,
      adshow1: false,
      adshow2: false,
      curActive: 0,
      curType: 0,
      noticeData: [],
      bannerData: [],
      adData: [],
      gg1Data: [],
      gg2Data: [],
      gg3Data: [],
      gg4Data: [],
      gg5Data: [],
      gg6Data: [],
      gg7Data: [],
      gg8Data: [],
      gg9Data: [],
      gg10Data: [],
      gg11Data: [],
      gg12Data: [],
      gg13Data: [],
      tagList: [],
      allTabList: [],
      cmsList: [],
      cmsListBox: [],
      cmsPagesize: 5,
      cmsPagenum: 1,
      homeOther: [],
      gsbbsData: [],
      photoList: [], //图库
      photoList2: [], //图库
      photoList3: [], //图库
      photoList4: [], //图库
      photoList5: [], //图库
      photoList6: [], //图库
      photoList7: [], //图库
      photoList8: [], //图库
      photoList9: [], //图库
      photoList10: [], //图库
      photoList11: [], //图库
      photoList12: [], //图库
      photoList13: [], //图库
      photoList14: [], //图库
      photoList15: [], //图库
      photoList16: [], //图库      
      photoList17: [], //图库      
      photoList18: [], //图库
      photoList19: [], //图库      
      photoList20: [], //图库
      toData: [
        {
          to: '/video',
        },
        {
          to: '/data/all',
        },
        {
          to: '/master',
        },
        {
          to: '/forum', //论坛
        },
        {
          to: '/gallery',
        },
        {
          to: '/guess',
        },
        {
          to: '/formula',
        },
        {
          to: '/tools',
        },
      ],
      hknextOpenTime: new Date(),
      amnextOpenTime: new Date(),
      xamnextOpenTime: new Date(),
      xxamnextOpenTime: new Date(),
      twnextOpenTime: new Date(),
      xjbnextOpenTime: new Date(),
      dataList: [],
      curlist: [],
      // isBottom: false,
      loading: false,
      finished: false,
      page: 1,
      fIsGg: 0,
      hotGameData: [],
      curPhoto: 0,
      keepList: [],
    }
  },
  computed: {
    ...mapGetters({
      avatar: 'user/avatar',
      username: 'user/username',
      sysInfo: 'user/sysInfo',
      userInfo: 'user/userInfo',
    }),
    czTool() {
      return czTool
    },
    isShowBuy() {
      return process.env.VUE_APP_BASE_ShowBuy=='1'
    },
    skincss() {

      if (process.env.VUE_APP_BASE_Skin && process.env.VUE_APP_BASE_Skin != 'default') {
        return '/mobile/skin/' + process.env.VUE_APP_BASE_Skin + '.css?'+process.env.VUE_APP_VERSION
      }
      return '/mobile/skin/default.css'
    },
  },

  async created() {
    let fIsGg = sessionStorage.getItem('fIsGg')
    if (fIsGg != null) {
      this.fIsGg = fIsGg
    } else {
      this.fIsGg = 0
    }

    let { list: lotteryData } = await getLottery()
    let curlottery = localStorage.getItem('curlottery')
    if (!curlottery) {
      localStorage.setItem('curlottery', lotteryData[0].gameNo)
      curlottery = lotteryData[0].gameNo
    }
    this.lotteryData = lotteryData
    // let banner = await getBanner()
    // if (banner.success) {
    //   this.bannerData = banner.list
    // }
    getAd().then(
      (
        res //底部广告
      ) => {
        this.adData = res.list
      }
    )
    getGG1().then(
      (
        res //主页广告1
      ) => {
        this.gg1Data = res.list
      }
    )
    getGG2().then(
      (
        res //主页广告2
      ) => {
        this.gg2Data = res.list
      }
    )

    getGG3().then(
      (
        res //主页广告3
      ) => {
        this.gg3Data = res.list
      }
    )
    getGG4().then(
      (
        res //主页广告4
      ) => {
        this.gg4Data = res.list
      }
    )
    getGG5().then(
      (
        res //主页广告5
      ) => {
        this.gg5Data = res.list
      }
    )
    getGG6().then(
      (
        res //主页广告6
      ) => {
        this.gg6Data = res.list
      }
    )
    getGG7().then(
      (
        res //主页广告7
      ) => {
        this.gg7Data = res.list
      }
    )
    getGG8().then(
      (
        res //主页广告8
      ) => {
        this.gg8Data = res.list
      }
    )
    getGG9().then(
      (
        res //主页广告9
      ) => {
        this.gg9Data = res.list
      }
    )
    getGG10().then(
      (
        res //主页广告10
      ) => {
        this.gg10Data = res.list
      }
    )
    getGG11().then(
      (
        res //主页广告11
      ) => {
        this.gg11Data = res.list
      }
    )
    getGG12().then(
      (
        res //主页广告12
      ) => {
        this.gg12Data = res.list
      }
    )
    getGG13().then(
      (
        res //主页广告13
      ) => {
        this.gg13Data = res.list
      }
    )
    this.getKeepData()
    // 热们推荐
    getIndexGames().then((res) => {
      this.hotGameData = res.list
    })

    getCMSTagList().then((res) => {
      this.allTabList = res.list
      this.tagList = this.allTabList.slice(0, 6)
    })
    this.curActive = curlottery
    this.getCMSIndex(curlottery)
    await this.getForumType()
    // getNotice().then((res) =>

    this.getDataPhotoCMS(curlottery)

    getNotice().then((res) => {
      this.noticeData = res.list
    })
    this.getGsBBS()
    this.onLoad()
  },
  async mounted() {
    const ad1 = Cookies.get('ad1')
    if (!ad1) this.adshow1 = true //开启弹窗
    this.getNextTime()
  },

  methods: {    
    getLogo(){
	if(process.env.VUE_APP_BASE_LOGO.indexOf('0600.one')>-1){
	  return logo0600
	}else if(process.env.VUE_APP_BASE_LOGO.indexOf('00862zxcom')>-1){
	  return logo00862zxcom
	}else{
	  return logo
	}
//return require(process.env.VUE_APP_BASE_LOGO)
// require('@/assets/images/home/600kjaa.0060.one.gif')
     // return require('@/' + process.env.VUE_APP_BASE_LOGO)
},
    setKeep(data) {
      // 收藏
      // if (this.isLogin()) {
      let token = localStorage.getItem('token')
      if (!token) {
        return Toast('请先登录！')
      }
      let uID = this?.userInfo?.id
      let uFID = data?.FID
      let uFState = 1
      if (this.keepList.indexOf(uFID) != -1) {
        uFState = 0
      }
      UpdateForumFavorites({ token, uID, uFID, uFState }).then((res) => {
        if (res.success) {
          this.getKeepData()
          // this.isKeep = !this.isKeep
        }
      })
      // }
    },
    // 获取收藏数据
    async getKeepData() {
      let token = localStorage.getItem('token')
      let uID = this?.userInfo?.id || 0
      let uFID = this?.detailInfo?.FID
      // if (!uFID) return
      let { list: keepData } = await getMyFavorites({ token, uID })

      let res = keepData.map((item) => {
        return item.FID
      })

      // let isKeep = keepData.find(
      //   (item) => item.FUserID == uID && item.FID == uFID
      // )
      console.log('keepList', res)
      // if (isKeep) this.isKeep = true
      this.keepList = res
    },
    onTsClick(event) {
      let cont = $(event.target).parents('.cms-box').find('.cms-cont')
      let foot = $(event.target).parents('.cms-foot')
      if (cont.hasClass('cms-unheight')) {
        cont.removeClass('cms-unheight')
        cont.slideDown()
        foot.find('.cms-sq span').text('收起')
        foot.find('.cms-sq i').css({ transform: 'rotate(0)' })
      } else {
        cont.addClass('cms-unheight')
        cont.slideUp()
        foot.find('.cms-sq span').text('展开')
        foot.find('.cms-sq i').css({ transform: 'rotate(180deg)' })
      }
    },
    getMore(val) {
      if (val == 1) {
        return (this.tagList = this.allTabList.slice(0, 6))
      }
      this.tagList = this.allTabList
    },
    jumpWfPage(data) {
      if (data.gameType == 0) {
        this.jumpMbVipPage(data)
      }
      let type = 'ssc'
      let gameType = data.gameType
      if (gameType == 1) {
        type = 'ssc'
      } else if (gameType == 2) {
        type = 'syx5'
      } else if (gameType == 3) {
        type = 'klten'
      } else if (gameType == 4) {
        type = 'ftc'
      } else if (gameType == 5) {
        type = 'lhc'
      } else if (gameType == 6) {
        type = 'k3'
      } else if (gameType == 7) {
        type = 'pk10'
      } else if (gameType == 8) {
        type = 'pcdd'
      }
      let path = '/lottery/' + type + '/' + data.gameNo
      this.$router.push(path)
    },
    jumpMbVipPage(data) {
      if (!this.userInfo?.userName) return this.$router.push('/login')
      let url =
        '/lottery/MbVipapisIndex?api_code=' +
        data.gameInfo +
        '&isMobile=1&' +
        data.remark +
        '&gameType=' +
        data.gameNo
      this.$router.push(url)
    },
    async getDataPhotoCMS(curlottery) {
      let type = 0
      if (curlottery == '90001') {
        type = 5
      } else if (curlottery == '90002') {
        type = 21
      } else if (curlottery == '90004') {
        type = 13
      } else {
        type = 37
      }
      // getDataPhotoCMS({ uGameNo: curlottery, uType: type }).then((res) => {
      //   this.photoList = res.list
      // })
      let VUE_APP_BASE_IndexDataPhotoCount=parseInt(process.env.VUE_APP_BASE_IndexDataPhotoCount)
      console.log(VUE_APP_BASE_IndexDataPhotoCount)
      if (VUE_APP_BASE_IndexDataPhotoCount > 1) {
        let uSbutypes=['主攻五码','黄金一码','梅花一肖','预测玄机','六合透特','凤凰阁','白姐暴肖','玄机来料','无字天书',
        '五行白姐','金牌半波','平特王中正','五行玄机','铜宝典','全集心水','绝杀来料','天王宝典','九肖30码','八仙宝典']
        for (let i = 1; i < 20 && i < VUE_APP_BASE_IndexDataPhotoCount; i++) {
          await getDataPhoto2({ uGameNo: curlottery, uType: type, uSubType: '主攻五码' }).then((res) => {
            this["photoList"+i] = res.list
          }).catch(e => { })
        }
      }else{
        await getDataPhoto2({ uGameNo: curlottery, uType: type, uSubType: '主攻五码' }).then((res) => {
            this["photoList2"] = res.list
          }).catch(e => { })
      }
      
    },
    getGsBBS() {
      let uGameNo = this.curActive
      if(process.env.VUE_APP_BASE_ShowGaoshoubanbang!='1'){
        return;
      }
      getGsBBS({ uGameNo }).then((res) => {
        this.gsbbsData = res.list
      })
    },
    jumpDetail(id) {
      let type = localStorage.getItem('curlottery')
      let path = '/detail/' + type + '?uFID=' + id
      this.$router.push(path)
    },

    yjbptJs(numStr, content) {
      let arr = numStr.split(',')
      let relust = {
        sx: this.czTool.lhcSx(arr.slice(-1).toString()),
        num: arr.slice(-1).toString(),
        desc: '错',
        bool: false,
      }

      for (let i = 0; i < arr.length; i++) {
        let sx = this.czTool.lhcSx(arr[i])
        if (content.includes(sx)) {
          relust.sx = sx
          relust.num = arr[i]
          relust.desc = '准'
          relust.bool = true
          break
        }
      }
      return relust
    },
    yjbptJs2(numStr, content) {
      let arr = numStr.split(',')
      let relust = {
        sx: this.czTool.lhcSx(arr.slice(-1).toString()),
        num: arr.slice(-1).toString(),
        desc: '错',
        bool: false,
        len: 0,
      }

      for (let i = 0; i < arr.length; i++) {
        let sx = this.czTool.lhcSx(arr[i])
        if (content.includes(sx)) {
          // relust.sx = sx
          // relust.num = arr[i]
          // relust.desc = '个'
          relust.len++
        }
      }
      return relust
    },
    jumpCmsDetail(type) {
      let path = '/cmsdetail'
      let id = this.curActive
      this.$router.push(path + '/' + id + '?uType=' + type)
    },
    jumpForumPage(id, type) {
      let path = '/forum'
      this.$router.push(path + '/' + id + '?uType=' + type)
    },
    jumpPhotoPage(id, type) {
      let path = '/gallery'
      this.$router.push(path + '/' + id + '?uType=' + type)
    },
    countWordsWithoutHTML(text) {
      // 使用正则表达式移除HTML标签回车空格
      let cleanText = text.replace(/<[^>]*>/g, '').replace(/[\n\s]+/g, '')

      return cleanText
    },
    onLoad() {
      this.getCMSListByPage()
    },
    // getCMSIndex(uGameNo)  //getCMSIndex 方法一  加了判断  （彩种与彩种切换太快，就会报错getCMSIndex）
    //{
    //   if (this.cmsPagenum > 5) return
    //   let params = {
    //     uGameNo,
    //     PageSize: this.cmsPagesize,
    //     PageNo: this.cmsPagenum,
    //   }
    //   getCMSIndex(params).then((res) => {
    //     if (res.list && res.list.length) {
    //       this.cmsListBox.push(...res.list)
    //       const organizedData = this.cmsListBox.reduce((acc, item) => {
    //         // 如果累加器对象中还没有当前类型的数组，则初始化一个空数组
    //         if (!acc[item.FType]) {
    //           acc[item.FType] = []
    //         }
    //         // 将当前项添加到对应类型的数组中
    //         acc[item.FType].push(item)
    //         // 返回更新后的累加器对象
    //         return acc
    //       }, {}) // 初始化累加器对象为一个空对象

    //       // 将对象转换为二维数组的形式
    //       // console.log('organizedData', organizedData)
    //       const twoDimensionalArray = Object.values(organizedData)
    //       // console.log(twoDimensionalArray)
    //       this.cmsList = twoDimensionalArray
    //       this.cmsPagenum++
    //       this.getCMSIndex(uGameNo)
    //     }
    //   })
    // },

    async getCMSIndex(
      uGameNo //getCMSIndex 方法二  去掉了判断 切换太快也无报错
    ) {
      let params = {
        uGameNo,
        PageSize: this.cmsPagesize,
        PageNo: this.cmsPagenum,
      }
      let allData = []
      let page = parseInt(process.env.VUE_APP_BASE_IndexShowCmsCount)

      for (let i = page; i >= 1; i--) {
        params.PageNo = i
        try {
          const { list } = await getCMSIndex(params).catch(e=>{})
          allData = allData.concat(list)
        } catch (ex) {
          console.log(ex)
        }
      }
      const organizedData = allData.reduce((acc, item) => {
        // 如果累加器对象中还没有当前类型的数组，则初始化一个空数组
        if (!acc[item.FType]) {
          acc[item.FType] = []
        }
        // 将当前项添加到对应类型的数组中
        acc[item.FType].push(item)
        // 返回更新后的累加器对象
        return acc
      }, {}) // 初始化累加器对象为一个空对象

      // 将对象转换为二维数组的形式
      // console.log('organizedData', organizedData)
      const twoDimensionalArray = Object.values(organizedData)
      function sort(){

      }
      function hashcode(obj) {
        // 将对象obj转换为字符串
        var str = JSON.stringify(obj);

        var hash = 0, i, chr, len;
        if (str.length === 0) return hash;
        for (i = 0, len = str.length; i < len; i++) {
          chr = str.charCodeAt(i);
          hash = ((hash << 5) - hash) + chr;
          hash |= 0; // Convert to 32bit integer
        }
        return hash;
      }
      let gameNameHashCode=this.lotteryData&&this.lotteryData.length>1? hashcode(this.lotteryData[0].gameName)/10000:10000
      twoDimensionalArray.sort(function(a,b){
        return hashcode(a)%gameNameHashCode-hashcode(b)%gameNameHashCode
      })
      twoDimensionalArray.reverse()
      this.cmsList = twoDimensionalArray
    },
    getCMSListByPage() {
      let uGameNo = this.curActive
      let PageSize = 5
      let PageNo = this.page
      getCMSListByPage({ uGameNo, PageSize, PageNo }).then((res) => {
        if (res.success) {
          if (!res.list || !res.list.length) {
            this.finished = true
            // this.isBottom = true
          } else {
            res.list.map((item) => {
              this.curlist.push(item)
            })
            this.page++
            this.loading = false
            this.orderData()
          }
        }
      })
    },
    orderData() {
      // console.log(this.curlist)
      let newData = []
      let obj = {}
      this.curlist.map((item, i) => {
        if (obj[item.FType]) {
          obj[item.FType].push(item)
        } else {
          obj[item.FType] = [item]
        }
      })

      for (const key in obj) {
        let curObj = {
          // FTypeSort: obj[key][0].FTypeSort,
          FSort: obj[key][0].FSort,
          FTypeName: obj[key][0].FType,
          // gameName: obj[key][0].gameName,
          // FTypeName: obj[key][0].FTypeName,
          // FShowID: obj[key][0].FShowID,
          children: obj[key],
        }
        newData.push(curObj)
      }
      this.dataList = orderBy(newData, ['FSort'], ['asc'])
    },
    // 获取八大类
    async getForumType() {
      await getForumType().then((res) => {
        let curlottery = localStorage.getItem('curlottery')
        let data = res.list
        data.sort((a, b) => a.FID - b.FID)
        let homeOther = data.filter((item) => item.FGameNo == curlottery)
        this.homeOther = merge(homeOther, this.toData)
        this.curType = this.homeOther[4].FID
      })
    },
    shuaxin() {
      this.getNextTime()
    },
    async getNextTime() {
      // let openDay = new Date()
      let curDate = this.$formatTime(new Date(), 'YYYY-MM-DD') + ' 21:31' //彩种按纽  直播开奖 显示
      var oneDay = 1000 * 60 * 60 * 24 //一天的毫秒数
      if (new Date().getTime() > new Date(curDate).getTime()) {
        let nextDay = new Date().getTime() * 1 + oneDay
        curDate = this.$formatTime(nextDay, 'YYYY-MM-DD') + ' 21:31'
      }

      let scamc = this.$formatTime(new Date(), 'YYYY-MM-DD') + ' ' + process.env.VUE_APP_BASE_DrawTime //私彩90005 //彩种按纽  直播开奖 显示
      var oneDay = 1000 * 60 * 60 * 24 //一天的毫秒数
      if (new Date().getTime() > new Date(scamc).getTime()) {
        let nextDay = new Date().getTime() * 1 + oneDay
        scamc = this.$formatTime(nextDay, 'YYYY-MM-DD')+ ' ' + process.env.VUE_APP_BASE_DrawTime
      }

      let scamc2 = this.$formatTime(new Date(), 'YYYY-MM-DD') + ' 23:30' //私彩90006 //彩种按纽  直播开奖 显示
      var oneDay = 1000 * 60 * 60 * 24 //一天的毫秒数
      if (new Date().getTime() > new Date(scamc2).getTime()) {
        let nextDay = new Date().getTime() * 1 + oneDay
        scamc2 = this.$formatTime(nextDay, 'YYYY-MM-DD') + ' 23:30'
      }

      // let oldopenDay = new Date()
      let oldcurDate = this.$formatTime(new Date(), 'YYYY-MM-DD') + ' 21:33'
      var oldoneDay = 1000 * 60 * 60 * 24 //一天的毫秒数
      if (new Date().getTime() > new Date(oldcurDate).getTime()) {
        let oldnextDay = new Date().getTime() * 1 + oldoneDay
        oldcurDate = this.$formatTime(oldnextDay, 'YYYY-MM-DD') + ' 21:33'
      }
      this.amnextOpenTime = curDate //新澳门
      this.xamnextOpenTime = scamc //新彩90005
      this.xxamnextOpenTime = scamc2 //新彩90006
      this.twnextOpenTime = this.getTwOpenTime()
      this.xjbnextOpenTime = oldcurDate
      let { list } = await getXgNextTime()
      this.hknextOpenTime = list[0]?.FOpenDate + ' ' + list[0]?.FOpenTime
    },

    getXjbOpenTime() {
      let day = new Date().getDay()
      if (day == 0) {
        let oneDay = 1000 * 60 * 60 * 24 //一天的毫秒数
        let nextTime = new Date().getTime() + oneDay
        let curDate =
          this.$formatTime(new Date(nextTime), 'YYYY-MM-DD') + ' 18:40'
        return curDate
      } else if (day == 1) {
        let curDate = this.$formatTime(new Date(), 'YYYY-MM-DD') + ' 18:40'
        var oneDay = 1000 * 60 * 60 * 24 * 3
        if (new Date().getTime() > new Date(curDate).getTime()) {
          let nextDay = new Date().getTime() + oneDay
          curDate = this.$formatTime(new Date(nextDay), 'YYYY-MM-DD') + ' 18:40'
        }
        return curDate
      } else if (day == 2) {
        let oneDay = 1000 * 60 * 60 * 24 * 2
        let nextTime = new Date().getTime() + oneDay
        let curDate =
          this.$formatTime(new Date(nextTime), 'YYYY-MM-DD') + ' 18:40'
        return curDate
      } else if (day == 3) {
        let oneDay = 1000 * 60 * 60 * 24
        let nextTime = new Date().getTime() + oneDay
        let curDate =
          this.$formatTime(new Date(nextTime), 'YYYY-MM-DD') + ' 18:40'
        return curDate
      } else if (day == 4) {
        let curDate = this.$formatTime(new Date(), 'YYYY-MM-DD') + ' 18:40'
        var oneDay = 1000 * 60 * 60 * 24 * 4
        if (new Date().getTime() > new Date(curDate).getTime()) {
          let nextDay = new Date().getTime() + oneDay
          curDate = this.$formatTime(new Date(nextDay), 'YYYY-MM-DD') + ' 18:40'
        }
        return curDate
      } else if (day == 5) {
        let oneDay = 1000 * 60 * 60 * 24 * 3
        let nextTime = new Date().getTime() + oneDay
        let curDate =
          this.$formatTime(new Date(nextTime), 'YYYY-MM-DD') + ' 18:40'
        return curDate
      } else if (day == 6) {
        let oneDay = 1000 * 60 * 60 * 24 * 2
        let nextTime = new Date().getTime() + oneDay
        let curDate =
          this.$formatTime(new Date(nextTime), 'YYYY-MM-DD') + ' 18:40'
        return curDate
      }
    },
    getTwOpenTime() {
      let day = new Date().getDay()
      if (day == 0) {
        let oneDay = 1000 * 60 * 60 * 24 * 2
        let nextTime = new Date().getTime() + oneDay
        let curDate =
          this.$formatTime(new Date(nextTime), 'YYYY-MM-DD') + ' 20:50'
        return curDate
      } else if (day == 1) {
        let oneDay = 1000 * 60 * 60 * 24
        let nextTime = new Date().getTime() + oneDay
        let curDate =
          this.$formatTime(new Date(nextTime), 'YYYY-MM-DD') + ' 20:50'
        return curDate
      } else if (day == 2) {
        let curDate = this.$formatTime(new Date(), 'YYYY-MM-DD') + ' 20:50'
        var oneDay = 1000 * 60 * 60 * 24 * 3
        if (new Date().getTime() > new Date(curDate).getTime()) {
          let nextDay = new Date().getTime() + oneDay
          curDate = this.$formatTime(new Date(nextDay), 'YYYY-MM-DD') + ' 20:50'
        }
        return curDate
      } else if (day == 3) {
        let oneDay = 1000 * 60 * 60 * 24 * 2
        let nextTime = new Date().getTime() + oneDay
        let curDate =
          this.$formatTime(new Date(nextTime), 'YYYY-MM-DD') + ' 20:50'
        return curDate
      } else if (day == 4) {
        let oneDay = 1000 * 60 * 60 * 24
        let nextTime = new Date().getTime() + oneDay
        let curDate =
          this.$formatTime(new Date(nextTime), 'YYYY-MM-DD') + ' 20:50' //彩种按纽  直播开奖 显示
        return curDate
      } else if (day == 5) {
        let curDate = this.$formatTime(new Date(), 'YYYY-MM-DD') + ' 20:50'
        var oneDay = 1000 * 60 * 60 * 24 * 4
        if (new Date().getTime() > new Date(curDate).getTime()) {
          let nextDay = new Date().getTime() + oneDay
          curDate = this.$formatTime(new Date(nextDay), 'YYYY-MM-DD') + ' 20:50'
        }
        return curDate
      } else if (day == 6) {
        let oneDay = 1000 * 60 * 60 * 24 * 3
        let nextTime = new Date().getTime() + oneDay
        let curDate =
          this.$formatTime(new Date(nextTime), 'YYYY-MM-DD') + ' 20:50'
        return curDate
      }
    },

    jump(path, id, type) {
      if (id == 900011) {
        this.$router.push('/data/list/' + id + '?title=高手榜料&uType=' + type)
      } else if (path) {
        this.$router.push(path + '/' + id + '?uType=' + type)
      }
    },
    jumpToTag(id) {
      let y = $('#tagblock-' + id).offset().top
      window.scrollTo({ top: y, left: 0, behavior: 'smooth' })
    },
    onCut(bool) {
      if (bool) {
        this.$refs.swipeRef.next()
      } else {
        this.$refs.swipeRef.prev()
      }
    },
    onChange(index) {
      this.current = index
    },
    closeAd1() {
      Cookies.set('ad1', 'ad1', { expires: 0.25 })
      this.adshow1 = false
    },
    getTitle(lotteryid) {
      let relust = ''
      this.lotteryData.forEach((item) => {
        if (item.gameNo == lotteryid) {
          relust = item.gameName
        }
      })

      return relust
    },
    // 彩种切换
    onTab(lotteryid) {
      this.curType = 0
      // this.getForumType(lotteryid)
      this.curActive = lotteryid
      localStorage.setItem('curlottery', lotteryid)
      this.gsbbsData = []
      this.$store.dispatch('user/getCzid', lotteryid)
      this.cmsList = []
      this.cmsListBox = []
      this.photoList = []
      this.cmsPagenum = 1
      this.getCMSIndex(lotteryid)
      this.getDataPhotoCMS(lotteryid)
      this.curPhoto = 0
      this.$refs.drawRef.getLotteryData()
      this.dataList = []
      this.curlist = []
      // this.isBottom = false
      this.finished = false
      this.loading = true
      this.page = 1
      this.getGsBBS()
      this.getCMSListByPage()
    },
  },
}
</script>

<style lang="scss">
 .grayscale  {


filter: grayscale(100%);


}
.home-container {
  width: 100%;
  height: calc(100% - 1.25rem);
  padding-top: 1.25rem;
  background-color: #fff;
  // overflow-x: hidden;
  .home-head {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 1.25rem;
    background: #ffffff;
    padding: 0.1rem 0.2rem 0.1rem;
    z-index: 999;
    .head-cont {
      height: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      .head-img {
        height: 100%;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .head-btns {
        display: flex;
        gap: 0.2rem;
        a {
          display: flex;
          align-items: center;
          justify-content: center;
          border: none;
          outline: none;
          width: 1.5rem;
          height: 0.6rem;
          border-radius: 10px;
          border: 1px solid rgb(45 73 156);
          background-color: transparent;
          font-size: 24px;
          color: rgb(40 67 153);
          letter-spacing: 0.1rem;
          &:last-child {
            color: rgb(164 125 54);
            border-color: rgb(164 125 54);
          }
        }
      }
    }
  }
  // opacity: 0;
  .download-icon {
    display: flex;
    align-items: center;
  }

  .kf-icon {
    display: flex;
    flex-direction: column;

    b {
      font-size: 14px;
      color: #fff;
      line-height: 1;
    }
  }

  .home-banner {
    position: relative;
    // background: url('../../assets/images/home/banner-bg.png') no-repeat;
    position: relative;
    background-color: #c22424;
    height: 4.9rem;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;

    .my-swipe .van-swipe-item {
      min-height: 3.2rem;

      // color: #fff;
      // font-size: 20px;
      // line-height: 150px;
      // text-align: center;
      // background-color: #39a9ed;
      a {
        display: inline-block;
        min-height: 3.2rem;
        height: 100%;

        img {
          min-height: 3.2rem;
          height: 100%;
          border-radius: 0.346667rem;
          width: 100%;
        }
      }
    }
  }

  .home-notice {
    display: flex;
    align-items: center;
    margin: 5px 10px;
    border-radius: 0.48rem;
    background: #fff;

    img {
      margin-left: 0.533333rem;
      width: 0.506667rem;
      height: 0.533333rem;
    }

    .van-notice-bar {
      flex: 1;
      padding: 0 0.266667rem;
      height: 0.933333rem;
      border-radius: 0.48rem;
    }
  }


  .home-bannerFriend {
    display: flex !important;

    justify-content: center;

    align-items: center;
    margin: 0.133333rem 0.266667rem 0;

    img {
      width: 100%;
    }
  }

  .home-other {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin: 0.133333rem 0 0.266667rem;
    margin-bottom: unset;
    border: 0.026667rem solid #eee;
    border-bottom-width: 0;
    background-color: #f2f2f2;
    width: 100%;
    overflow: hidden;

    li {
      padding: 0.293333rem 0.213333rem;
      // width: 2.366667rem;
      width: 2.49rem;
      // width: 100%;
      border-color: #eee;
      border-style: solid;
      border-width: 0.026667rem 0.026667rem 0 0;
      background-color: #fff;
      box-sizing: border-box;

      a {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;

        i {
          display: inline-block;
          width: 1.226667rem;
          height: 1.226667rem;
          // background-image: url('../../assets/images/home/router_icon.png');
          background-repeat: no-repeat;
          // background-size: 800% 100%;
          background-size: contain;

          &.i0 {
            background-position: 0 0;
          }

          &.i1 {
            background-position: -1.226667rem 0;
          }

          &.i2 {
            background-position: -2.453333rem 0;
          }

          &.i3 {
            background-position: -3.68rem 0;
          }

          &.i4 {
            background-position: -4.906667rem 0;
          }

          &.i5 {
            background-position: -6.133333rem 0;
          }

          &.i6 {
            background-position: -7.36rem 0;
          }

          &.i7 {
            background-position: -8.586667rem 0;
          }
        }

        p {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-top: 0.106667rem;

          strong {
            font-size: 0.373333rem;
            font-family: PingFang SC-Semibold, PingFang SC;
            font-weight: 600;
          }

          span {
            white-space: nowrap;
            font-size: 0.32rem;
            -webkit-transform: scale(0.7);
            transform: scale(0.7);
          }
        }

        img {
          position: absolute;
          top: -0.133333rem;
          right: 0;

          &.new {
            right: -0.133333rem;
            width: 0.56rem;
            height: 0.266667rem;
          }

          &.hot {
            width: 0.4rem;
            height: 0.533333rem;
          }
        }
      }
    }
  }

  .home-adRent {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    // margin: 0.266667rem 0.293333rem 2.133333rem;
    margin: 0.266667rem 0 2.133333rem;
    margin-top: unset;
    background-color: #f2f2f2;
    border-top: 0.026667rem solid #eee;

    li {
      position: relative;
      border: 0.026667rem solid #eee;
      border-top-width: 0;
      background-color: #fff;

      > img {
        position: absolute;
        top: 0;
        left: 0;
        width: 0.72rem;
      }

      a {
        display: inline-block;
        width: 100%;

        img {
          width: 100%;
          height: 1.866667rem;
          object-fit: contain;
        }
      }
    }
  }

  .home-lottery {
    position: fixed;
    bottom: 1.386667rem;
    display: flex;
    // display: grid;
    // grid-template-columns: repeat(2, 1fr);
    // grid-gap: 0.133333rem;
    width: 100%;

    p {
      margin: 0 2px;
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 0.826667rem;
      font-size: 0.326667rem;
      color: #fff;
      background-color: #fff;
      border: solid 1px;
      margin: 0 0.02667rem;
      border-radius: 0.14rem;

      &::before {
        content: '';
        display: inline-block;
        width: 0.56rem;
        height: 0.56rem;
        background-repeat: no-repeat;
        background-size: 0.56rem;
        margin-right: 0.12rem;
      }

      &:nth-child(1) {
        color: #c22424;

        &::before {
          background-image: url(../../assets/images/home/type-1.png);
        }

        &.active {
          color: #fff;
          background: #c22424;

          &::before {
            background-image: url(../../assets/images/home/type-1-active.png);
          }
        }
      }

      &:nth-child(2) {
        color: #c10707;

        &::before {
          background-image: url(../../assets/images/home/type-2.png);
        }

        &.active {
          color: #fff;
          background: #c10707;

          &::before {
            background-image: url(../../assets/images/home/type-2-active.png);
          }
        }
      }

      &:nth-child(3) {
        color: #03d9d6;

        &::before {
          background-image: url(../../assets/images/home/type-3.png);
        }

        &.active {
          color: #fff;
          background: #03d9d6;

          &::before {
            background-image: url(../../assets/images/home/type-3-active.png);
          }
        }
      }

      &:nth-child(4) {
        color: #b907c1;

        &::before {
          background-image: url(../../assets/images/home/type-4.png);
        }

        &.active {
          color: #fff;
          background: #b907c1;

          &::before {
            background-image: url(../../assets/images/home/type-4-active.png);
          }
        }
      }

      &:nth-child(5) {
        color: #c22424;

        &::before {
          background-image: url(../../assets/images/home/type-1.png);
        }

        &.active {
          color: #fff;
          background: #c22424;

          &::before {
            background-image: url(../../assets/images/home/type-1-active.png);
          }
        }
      }

      &.active {
        background: rgba(250, 95, 95, 0.79);
      }
    }
  }

  .home-ad-overlay {
    position: relative;

    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;

    align-items: center;
    width: 100%;
    height: 100%;

    .home-ad-txt {
      position: relative;

      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      width: 7.466667rem;
      height: 10.72rem;
      background: url(../../assets/images/home/notice_bg.png) no-repeat;
      background-size: 100% 100%;

      .home-ad-swipe {
        padding-top: 3.466667rem;
        margin-left: 0.133333rem;
        width: 6.533333rem;
        word-wrap: break-word;
        word-break: break-all;

        // ::v-deep .van-swipe__track {
        //   min-height: 5.333333rem;
        //   overflow-x: auto;
        // }
        .home-ad-txts {
          padding: 0.266667rem 0.8rem;

          p {
            max-height: 5.333333rem;
            overflow-y: auto;
            font-size: 0.373333rem;
            white-space: pre-wrap;
            overflow-x: auto;
          }
        }
      }

      .custom-indicator {
        position: absolute;
        left: 0;
        bottom: 0;
        white-space: nowrap;
        text-align: center;
        padding-bottom: 0.266667rem;
        background-color: transparent;

        span {
          display: inline-block;
          border-radius: 0.48rem;
          width: 2.48rem;
          height: 0.96rem;
          line-height: 0.96rem;
          text-align: center;
          font-size: 0.373333rem;
          color: #fff;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          background-color: #c22424;
        }

        b {
          display: inline-block;
          width: 1.333333rem;
          font-weight: 400;
          font-size: 0.373333rem;
        }
      }

      .close {
        position: absolute;
        top: 0.266667rem;
        right: 0.4rem;
        width: 0.586667rem;
      }
    }
  }
}

.cont2-box {
  // margin: 0.133333rem 0.266667rem 0;
  margin: 0.133333rem 0 0;
  background: #fff;
  .title {
    //background-color: #c22424 !important;
    //background-image: linear-gradient(rgba(231, 111, 81, 0.376), rgba(231, 111, 81, 0.13));
    //border-radius: 10px;
    //border: solid 1px rgba(111, 94, 81, 0.3);
    //padding: 3px;
    font-weight: 700;
    color: rgb(255, 255, 255);
    font-size: 24px;
    text-align: center;
    margin: 0;
    padding: 5px 0;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.5px solid #e5e5e5;
    font-size: 16px;
    padding: 5px 2px;
    color: #2161dd;
  }
  .bbsCont {
    // line-height: 45px;
    // display: flex;
    // align-items: center;
    // justify-content: space-between;
    // border-bottom: 1px solid #e5e5e5;
    // padding: 0 15px;
    // &:nth-of-type(2n) {
    //   background: #e2daff;
    // }
    // .bbsCont-left {
    //   padding: 0.32rem 0.133333rem;
    //   cursor: pointer;
    //   display: -webkit-box;
    //   display: -ms-flexbox;
    //   display: flex;
    //   -webkit-box-pack: justify;
    //   -ms-flex-pack: justify;
    //   justify-content: space-between;

    //   p {
    //     font-size: 12px;
    //     text-overflow: ellipsis;
    //     white-space: nowrap;
    //     overflow: hidden;
    //   }
    //   span {
    //     min-width: 20px;
    //   }
    // }
    line-height: 45px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(111, 81, 96, 0.3);
    padding: 0 15px;
    .bbsCont-left {
      display: flex;
      align-items: center;

      img {
        width: 20px;
        margin: 0 10px;
      }
      h4 {
        font-size: 16px;
        max-width: 190px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    .bbsCont-right {
      span {
        font-size: 14px;
        color: #cfcfd0;
      }
    }
  }
}

.tag-list {
  display: flex;
  flex-wrap: wrap;
  padding-top: 10px;
  background: #fff;
  li {
    display: flex;
    // width: calc(20% - 5px);
    margin-left: 4px;
    margin-bottom: 6px;
    &.active {
      a {
        color: #fff;
        background: #c10000;
      }
    }
    a {
      flex: 1;
      text-align: center;
      line-height: 24px;
      color: #fff;
      // background: #f13131;
      background-color: #fff;
      color: rgb(72 72 72);
      border: 1px solid #e5e7eb;
      border-radius: 24px;
      font-size: 12px;
      padding: 0 15px;
    }
  }
}


.lot-hotGame {
  padding: 10px;
  .lot-list {
    display: flex;
    flex-wrap: wrap;
    li {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 25%;
      padding: 20px 0;
      img {
        width: 65%;
        margin-bottom: 10px;
      }
      p {
        font-weight: bold;
      }
    }
  }
}
</style>
<style scoped>
.sx {
  background: #e5e5e5;
}

.sx table {
  margin-top: 0px;
}

.sx th {
  height: 30px;
  background: #f1f1f1;
  font-size: 26px;
}

.sx td {
  padding: 9px 0;
  text-align: center;
  border: solid 1px #d8ccb4;
  word-wrap: break-word;
  background: #fff;
  font-size: 30px;
  line-height: 30px;
}

.sx td.a-left {
  text-align: left;
  padding-left: 5px;
  padding-right: 5px;
}

.sx span {
  line-height: 36px;
  margin: 5px 2px;
  padding: 0 2px;
  font-size: 33px;
  color: #fff;
  display: inline-block;
  background: #f00;
}

.sx span.green {
  background: #009933;
}

.sx span.blue {
  background: #3366ff;
}

.grey-line {
  border-top: solid 0px #e5e5e5;
  zoom: 0.8;
}

ul.sx1 {
}

ul.sx1 li {
  float: left;
  width: 33.33%;
  padding: 5px 0;
}

ul.sx1 li dl {
}

ul.sx1 li dl dt {
  font-size: 38px;
}

ul.sx1 li dl dt img {
  width: 60px;
  height: 60px;
}

ul.sx1 li dl dd {
}
</style>